import React, { Component } from 'react';
import { Button, Form, Message } from 'semantic-ui-react'

class UpOne extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          errorMessage: '',
          receipt1: ''
          // loading: false
        };
      }
      
    onSubmit= async (event) => {
        event.preventDefault()
        this.setState({ loading: true, errorMessage: '' });
        try {
        const member = this.levelOne.value
        let amount = window.web3.utils.toWei('0.012', 'Ether')
        this.props.payLevelOne(member, amount)
        } catch (err) {
        this.setState({ errorMessage: err.message });
    }
    }
    render() {
      const levelOneReceipt = this.props.pastPayments.find(rw => rw.returnValues.to === this.props.levelOne);
      
        return (
            <Form onSubmit={this.onSubmit} error={!!this.state.errorMessage}>
            <input 
              id="levelOne"
              type="hidden"
              value={this.props.levelOne}
              ref={(input) => { this.levelOne = input }}
          />
              <Message error header="Oops!"  content='Please contact the administrator. Thank you'/>
              {levelOneReceipt ?
            <></>
            : <><Button loading={this.state.loading} color='yellow' content='Yellow'> Confirm Agreement</Button></>
            }
          </Form> 
        )
    }
}
export default UpOne;
