import React, { Component } from 'react';

import Web3 from 'web3';
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Tree from './abis/Tree.json';
import Register from './Register';
import Success from './Success';
import NotFound from './NotFound';

import Products from './components/shop/Products';
import Cart from './pages/Cart'
import './main.css';

//Redux
import Category from './Category';
import ProductDetail from './components/feeds/shopping/ProductDetail';
import Shop from './components/feeds/shopping/Shop';
import UploadProducts from './components/shop/UploadProducts';
import AddListingPics from './components/feeds/seller/AddListingPics';
import Policy from './agreements/Policy';
import UploadProfile from './components/accounts/UploadProfile';
import MyAds from './components/feeds/shopping/MyAds';
import EditProfile from './components/accounts/EditProfile';
import OpenShop from './components/feeds/seller/OpenShop';
import ShopSummary from './components/feeds/seller/ShopSummary';
import UploadSuccess from './components/shop/Success';
import StartSelling from './components/sell/StartSelling';
import Enquiries from './components/backend/Enquiries';
import EditProduct from './components/feeds/seller/EditProduct';
import Home from './components/dashboard/Home';
import Store from './components/dashboard/Store';
import Policy2 from './agreements/Policy2';
import { productsData } from './api/api';
import Signin from './pages/Signin';
import Registration from './pages/Registration';


let noSponser = '0x0000000000000000000000000000000000000000'
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: '',
      balance : '',
      createdMembers: [],
      pastPayments: [],
      urls: [],
      account_profile: [],
      businessRegistration: [],
      products:[],
      ListingEnquries:[],
      categories: [],
      second_subcategories: [],
      dict:[],
      images:[],
      country: [],
      cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
      category: '',
      sort: '',

      acct: null,
      affiliates: null,
      marketplace: null,

      dai: null,
      daiBalance: 0,

      //Affiliate Stuff
      invitedBy: '',
      levelOne: '',
      levelTwo: '',
      levelThree: '',
      levelFour: '',
      show:true,
      tickets: [],
      ticketNetwork: null,
    };
    this.registerAccount = this.registerAccount.bind(this)
    this.payAccount = this.payAccount.bind(this)
    this.paySponser = this.paySponser.bind(this)
    this.payLevelOne = this.payLevelOne.bind(this)
    this.transfer = this.transfer.bind(this)
  } 
  
  async componentDidMount() {
    await this.loadWeb3()
    await this.loadApi()
    await this.loadBlockchainData()
  }
  
  
  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Please install MetaMask')
      window.location.assign("https://metamask.io/")
    }
  }
  

  async loadApi() {

      axios
      .get(`https://paysell.app/api/account_profile/`)
      .then((res) => this.setState({ account_profile: res.data }))
      .catch((err) => console.log(err));

      axios
      .get(`https://paysell.app/api/business-info/`)
      .then((res) => this.setState({ businessRegistration: res.data }))
      .catch((err) => console.log(err));

      axios
      .get(`https://paysell.app/ads/products/`)
      .then((res) => this.setState({ products: res.data }))
      .catch((err) => console.log(err));

      axios
      .get(`https://paysell.app/api/urls/`)
      .then((res) => this.setState({ urls: res.data }))
      .catch((err) => console.log(err));

      axios
      .get(`https://paysell.app/api/categories/`)
      .then((res) => this.setState({ categories: res.data }))
      .catch((err) => console.log(err));

      axios
      .get(`https://paysell.app/api/second_subcategories/`)
      .then((res) => this.setState({ second_subcategories: res.data }))
      .catch((err) => console.log(err));

      axios
      .get(`https://paysell.app/api/dict/`)
      .then((res) => this.setState({ dict: res.data }))
      .catch((err) => console.log(err));

      axios
      .get(`https://paysell.app/ads/images/`)
      .then((res) => this.setState({ images: res.data }))
      .catch((err) => console.log(err)); 

      axios
      .get(`https://paysell.app/ads/contact_supplier/`)
      .then((res) => this.setState({ ListingEnquries: res.data }))
      .catch((err) => console.log(err)); 

      axios
      .get(`https://restcountries.com/v3.1/all`)
      .then((res) => this.setState({ country: res.data }))
      .catch((err) => console.log(err)); 

  }
  
  
  async loadBlockchainData() {

    const web3 = window.web3
    // Load account
    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })
  
    // Network ID
    const networkId = await web3.eth.net.getId()
    const networkData = Tree.networks[networkId]

    if(networkData) {
      const ticketNetwork = new web3.eth.Contract(Tree.abi, networkData.address)
      this.setState({ ticketNetwork })

      const createdMembers = await ticketNetwork.getPastEvents('Summary', { fromBlock: 0, toBlock: 'latest', filter: {inviter: this.state.account}})
      this.setState({ createdMembers })

      const pastPayments = await ticketNetwork.getPastEvents('Payments', { fromBlock: 0, toBlock: 'latest', filter: {from: this.state.account}})
      this.setState({ pastPayments })
      
      const invitedBy = await ticketNetwork.methods.tree(this.state.account).call()
      this.setState({ invitedBy })

      const levelOne = await ticketNetwork.methods.tree(this.state.invitedBy.inviter).call()
      this.setState({ levelOne })

      const levelTwo = await ticketNetwork.methods.tree(this.state.levelOne.inviter).call()
      this.setState({ levelTwo })

      const levelThree = await ticketNetwork.methods.tree(this.state.levelTwo.inviter).call()
      this.setState({ levelThree })
       
    } else {
      window.alert('SocialNetwork contract not deployed to detected network.')
    }
  }

  registerAccount(inviter, subscription, Amount) {
    this.state.ticketNetwork.methods.enter(inviter, subscription).send({ from: this.state.account, value: Amount })
    .once('receipt', (receipt) => {
      this.setState({ loading: false })
      window.location="/policy-1"
    })
  }

  paySponser(member, amount) {
    this.setState({ loading: true })
    this.state.ticketNetwork.methods.pay(member).send({ from: this.state.account, value: amount})
    .once('receipt', (receipt) => {
      this.setState({ loading: false })
      window.location="/policy-2"
    })
  }

  payLevelOne(member, amount) {
    this.setState({ loading: true })
    this.state.ticketNetwork.methods.pay(member).send({ from: this.state.account, value: amount})
    .once('receipt', (receipt) => {
      this.setState({ loading: false })
      window.location="/"
    })
  }


  payAccount(member, amount) {
    this.setState({ loading: true })
    this.state.acct.methods.pay(member).send({ from: this.state.account, value: amount})
    .once('receipt', (receipt) => {
      this.setState({ loading: false })
      window.location="/"
    })
  }
  
  

  transfer(recipient, amount) {
    this.setState({ loading: true })
    this.state.dai.methods.transfer(recipient, amount).send({ from: this.state.account })
    .once('receipt', (receipt) => {
      this.setState({ loading: false })
      window.location="/success/"
    })
  }

  render() {
    const {account_profile,  businessRegistration, products, ListingEnquries, images, account, daiBalance, cartItems, categories, second_subcategories, country, dict} = this.state;
    return (
    <>
        <Router>
          <Switch>
            <Route path="/cart">
              <Cart account={account} />
            </Route>
            <Route path="/signin">
              <Signin />
            </Route>
            <Route path="/registration">
              <Registration />
            </Route>
            <Route exact path="/product-upload">
              <UploadProducts account={account} categories={categories} dict={dict} />
            </Route>
            
            <Route exact path={`/classifieds`}>
              <Products 
                daiBalance={daiBalance} 
                businessRegistration={ businessRegistration}
                addToCart={this.addToCart} 
                categories={categories}
                second_subcategories={second_subcategories}
                account={account}
                sponser={this.state.invitedBy.inviter}
                products={products}
                cartItems={cartItems} 
                dict={dict} 
              />
            </Route>
            <Route exact path={`/store`} 
          >
              <Store
                daiBalance={daiBalance} 
                businessRegistration={ businessRegistration}
                addToCart={this.addToCart} 
                categories={categories}
                second_subcategories={second_subcategories}
                account={account}
                sponser={this.state.invitedBy.inviter}
                products={products}
                cartItems={cartItems} 
                loader={productsData}
                dict={dict} 
              />
            </Route>
          
            <Route exact path="/profile">
              <UploadProfile 
              account={account} 
              />
            </Route>
            <Route exact path="/enquiries">
              <Enquiries 
              ListingEnquries={ListingEnquries}
              daiBalance={daiBalance} 
              businessRegistration={ businessRegistration}
              addToCart={this.addToCart} 
              categories={categories}
              second_subcategories={second_subcategories}
              account={account}
              sponser={this.state.invitedBy.inviter}
              products={products}
              cartItems={cartItems} 
              dict={dict}  
              />
            </Route>
            <Route exact path="/start-selling">
              <StartSelling 
                 daiBalance={daiBalance} 
                 businessRegistration={ businessRegistration}
                 addToCart={this.addToCart} 
                 categories={categories}
                 second_subcategories={second_subcategories}
                 account={account}
                 sponser={this.state.invitedBy.inviter}
                 products={products}
                 cartItems={cartItems} 
                 dict={dict} 
              />
            </Route>
            <Route exact path="/business-registration/">
              <OpenShop account={account} dict={dict} country={country} />
            </Route>
            <Route exact path="/business-summary/">
              <ShopSummary account={account}
               businessRegistration={businessRegistration}
              />
            </Route>
            <Route exact path="/checkout">
              <Cart 
                cartItems={this.state.cartItems} 
                removeFromCart={this.removeFromCart}
                payAccount={this.payAccount} 
                transfer={this.transfer}
                dict={dict}
              />
              </Route>
              <Route exact path="/policy-1">
               <Policy account={account} 
                sponser={this.state.invitedBy.inviter} 
                paySponser={this.paySponser}
                pastPayments={this.state.pastPayments} />
              </Route>

              <Route exact path="/policy-2">
                <Policy2 account={account} 
                levelOne={this.state.levelOne.inviter} 
                payLevelOne={this.payLevelOne}
                pastPayments={this.state.pastPayments} />
              </Route>

              <Route exact path={"/myAds/"}  
                render={(props) => (
                <MyAds {...props} 
                daiBalance={daiBalance}
                businessRegistration={businessRegistration}
                categories={categories}
                account={account}
                products={products}
                addToCart={this.addToCart}
                cartItems={cartItems}  
                dict={dict}
                purchaseProduct={this.purchaseProduct} />
              )} />
          <Route exact path="/add-product">
            <UploadProducts account={account} categories={categories} dict={dict} />
          </Route>
          <Route exact path="/add-pics">
            <AddListingPics account={account} dict={dict}  />
          </Route>
          <Route exact path="/success">
            <Success account={account} />
          </Route>
          <Route exact path="/product-upload-success">
            <UploadSuccess account={account} />
          </Route>
          <Route exact path={`/:category`}  
            render={(props) => (
              <>
                <Category {...props}
                daiBalance={daiBalance}
                businessRegistration={businessRegistration}
                account={account}
                sponser={this.state.invitedBy.inviter}
                categories={categories}
                second_subcategories={second_subcategories}
                cartItems={cartItems}
                addToCart={this.addToCart} 
                dict={dict} 
                products={products} />
              </>
            )} />
          <Route path="/:id/update" render={(props) => (
            <EditProfile {...props}
            account={account} />
            )} />  
            <Route path="/:id/listing" render={(props) => (
            <EditProduct {...props}
            account={account} 
            categories={categories}
            dict={dict} 
             />
            )} /> 
          <Route exact path={`/shop/:shop`}  
            render={(props) => (
            <Shop {...props} 
            daiBalance={daiBalance}
            businessRegistration={businessRegistration}
            categories={categories}
            account={account}
            sponser={this.state.invitedBy.inviter}
            products={products}
            addToCart={this.addToCart}
            cartItems={cartItems}  
            dict={dict}
            purchaseProduct={this.purchaseProduct} />
          )} />
          <Route exact path={`/product-detail/:id`}  
            render={(props) => (
            <ProductDetail {...props} 
            profile={account_profile} 
            businessRegistration={businessRegistration}
            daiBalance={daiBalance}
            transfer={this.transfer}
            products={products}
            images={images}
            payAccount={this.payAccount}
            addToCart={this.addToCart} 
            cartItems={cartItems}
            dict={dict}
            account={account}
            sponser={this.state.invitedBy.inviter}
            />
          )} >
          </Route>
          <Route exact path={`/home`}>
             <Home daiBalance={daiBalance} 
                noSponser={noSponser}
                businessRegistration={ businessRegistration}
                addToCart={this.addToCart} 
                categories={categories}
                second_subcategories={second_subcategories}
                account={account}
                sponser={this.state.invitedBy.inviter}
                products={products}
                cartItems={cartItems} 
                dict={dict}  />
          </Route>

          <Route exact path="/">
            <Register account={account} 
            sponser={this.state.invitedBy.inviter}
            noSponser={noSponser}
            levelOne={this.state.levelOne}
            payLevelOne={this.state.payLevelOne}
            pastPayments={this.state.pastPayments}
            registerAccount={this.registerAccount}
            />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
          </Switch>
        </Router> 
        
      </>
    )
   
  }
}
export default App;
