import React from 'react'
import Layout from '../Layout'

export default function StartSelling({account, sponser, daiBalance, businessRegistration, categories,
    second_subcategories, dict, cartItems, products, ...props}) {
  return (
      <Layout account={account} sponser={sponser} daiBalance={daiBalance} businessRegistration={businessRegistration} categories={categories} 
          dict={dict} cartItems={cartItems}>
        <div className='w-full md:w-2/3 m-auto'> 
          <div className='font-bold text-4xl p-2'>Start Selling</div>
            <div className='rounded-lg bg-white p-10 border m-2 border-gray-200 hover:bg-gray-50'>    
              Follow a few easy steps to register for an Paysell seller account and get your Business Verification process done to build trust between you and your potential buyers. You can also choose to have a dedicated account manager who will help you through the process.
            </div>

            <div className='p-2'>
              <div className='p-4 mt-2 rounded-lg bg-white border border-gray-200 hover:bg-gray-50'>
                <div className='font-bold text-2xl pb-3'>Step 1</div>
                <div className='pb-2'>a) Make sure you are <strong>logged</strong> in your account. To login click blue button at the bottom left corner of your screen.</div>
                <div className='pb-2'>b) Scroll down to the bottom of the page and find <strong>Register shop</strong></div>
                <img src='/img/howto/register.png' alt='' />
                <div className='pt-2 pb-2'>c) Complete <strong>Registration form</strong></div>
              </div>
              <div className='p-4 mt-4 rounded-lg bg-white border border-gray-200 hover:bg-gray-50'>
                <div className='font-bold text-2xl pb-3'>Step 2</div>
                <div className='pt-2 pb-2'>a) Click yellow button <strong>My Account</strong> at the top right corner of your page</div>
                <img src='/img/howto/submit.png' alt='' />
                <div className='pt-2 pb-2'>b) Find and click <strong>Submit Ad</strong></div>
              </div>
              <div className='p-4 mt-4 rounded-lg bg-white border border-gray-200 hover:bg-gray-50'>
                <div className='font-bold text-2xl pb-3'>Step 3</div>
                <div className='pt-2 pb-2'>a) Complete the form</div>
                <img src='/img/howto/adform.png' alt='' />
                <div className='pb-2'>b) Click <strong>Submit Ad</strong></div>
              </div>
            </div>
          </div>

      </Layout>
  )
}
