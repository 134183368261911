import React from 'react'

export default function Footer({businessRegistration, account, sponser, noSponser}) {
        return (
            <footer className="footer w-full">
              <div className="flex gap-8 pb-5">
                <div className="flex text-left">
                <div className='pr-5'>
                    <div className='font-extrabold'>
                      About
                    </div>
                    <div>
                      <div className='text-sm'>
                      {sponser === noSponser ? (
                        <p className='pt-1'>
                          <a href="/"  rel="noreferrer">Affiliate Program</a>
                        </p>): <></>}
                        {/* <p className='pt-1'>
                          <a href="/register"  rel="noreferrer">Investor Info</a>
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="pr-5">
                   <div className='font-extrabold'>Make Money With Us</div> 
                   <div className='text-sm'>
                   {sponser !== noSponser ? (
                      <p className='pt-1'>
                        <a href="/business-registration/">Register shop</a>
                      </p>)
                      : <></>}
                      <p className='pt-1'>
                        <a href="/start-selling/">How to sell</a>
                      </p>
                   </div>
                    
                  </div>
                  <div className="mr-3">
                   <div className='font-extrabold'>Stay Connected</div> 
                   <div className='text-sm'>
                      <p className='pt-1'>
                      <a href="https://www.facebook.com/groups/paysell" target='_blank' rel="noreferrer">
                            Facebook
                          </a>
                      </p>
                   </div>
                  </div>
                 
                  
                </div>
            </div>
            © 2021 Powered by {' PaySell'}.
          </footer>
        )
    }