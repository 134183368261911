
import React, {useState, useEffect} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SmallLayout from '../shop/SmallLayout'
import axios from "axios";
import { Button,  Input} from "reactstrap";

const EditProfile = (props) => {

    let history = useHistory();
    const { id } = useParams();
    const [fname, setFname] = useState(null)
    const [lname, setLname] = useState(null)
    const [owner, setOwner] = useState(null)
    const [status, setStatus] = useState(null)

    let getData = async () => {
        const { data } = await axios.get(`https://paysell.app/api/update/profile/${id}/`)
        
        setFname(data.fname);
        setLname(data.lname);
        setOwner(data.owner); 
        setStatus(data.status);
    }
        
    useEffect(() => {
        getData()
    }, // eslint-disable-next-line
    [])

    const updateAcct = async () => {
        
        let formField = new FormData()
        
        formField.append('fname',fname)
        formField.append('lname',lname)
        formField.append('owner',owner)
        formField.append('status',status)

       

        await axios({
            method: 'PUT',
            url: `https://paysell.app/api/update/profile/${id}/`,
            data: formField
        }).then(response => {
            console.log(response.data);
            history.push("/success");
        })

    }

    return (
        <SmallLayout account={props.account}>
                    <p className='p-1'>
                        <Input
                            name="fname"
                            type="text"
                            onChange={(e) => setFname(e.target.value)}
                            value={fname}
                            placeholder='First Name'
                        />
                    </p>
                    <p className='p-1'>
                        <Input
                            name="lname"
                            type="text"
                            onChange={(e) => setLname(e.target.value)}
                            value={lname}
                            placeholder='Last Name'
                        />
                    </p>

                    <p className='p-1'>
                        <Input
                            name="owner"
                            type="hidden"
                            onChange={(e) => setOwner(e.target.value)}
                            value={owner}
                            placeholder='Owner'
                        />
                    </p>
                    <p className='p-1'>
                        <Input
                            name="status"
                            type="hidden"
                            onChange={(e) => setStatus(e.target.value)}
                            value={status}
                        />
                    </p>
                    <p className='pt-4'>
                        <Button onClick={updateAcct}> Submit</Button>
                    </p>
        </SmallLayout>
    )
  }
export default EditProfile;
