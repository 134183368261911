import React, { Component } from 'react';
import SmallLayout from '../components/shop/SmallLayout';
import UpOne from '../UpOne';

class Policy2 extends Component {
   
    render() {
  return <SmallLayout account={this.props.account} sponser={this.props.sponser}>
            <div className='text-sm'>    
            PART 2  PROHIBITED AND CONTROLLED ITEMS {this.props.sponser}
<br /><br />

4. WEAPONS<br />
4.1 Paysell.io does not permit the posting, offering for sale, or offering of purchase of weapons that can incapacitate or cause serious physical harm to others (e.g. stun guns, batons, crossbows)
4.2 While listing of most knives and other cutting instruments is permitted, switchblade knives, gravity knifes, knuckledusters (bladed or not), bladed handheld devices, and disguised knives are prohibited.
4.3 Paysell.io maintains discretion over what items are appropriate and may cause removal of a listing that it deems as a weapon.
<br /><br />
5. GOVERNMENT, LAW ENFORCEMENT AND MILITARY ISSUED ITEMS<br />
5.1 The following items are not permitted to be listed:<br />
(a) Articles of clothing or identification that claim to be, or appear similar to, official government uniforms.<br />
(b) Law enforcement badges or official law enforcement equipment from any public authority, including badges issued by the government of any country.<br />
(c) Military decorations, medals and awards, in addition to items with substantially similar designs.<br />
5.2 Police uniforms, police insignia and police vehicles may not be posted unless they are obsolete and in no way resemble current issue police uniforms, police insignia and police vehicles. This fact must be clearly stated within the posting description.<br /><br />
5.3 There are some police items that may be listed on the Paysell.io Website, provided they observe the following guidelines:<br />
(a) Authorized general souvenir items, such as hats, mugs, pins, pens, buttons, cuff links, T-shirts, money clips that do not resemble badges, and paperweights that do not contain badges.<br />
(b) Badges that are clearly not genuine or official (e.g. toy badges).<br />
(c) Historical badges that do not resemble modern law enforcement badges, provided that the item description clearly states that the badge is a historical piece at least 75 years old or issued by an organization which no longer exists.<br />
5.4 The following mass-transit related items are not permitted to be listed:<br />
(a) Any article of clothing or identification related to transportation industries, including but not limited to, commercial airline pilots, flight attendants, airport service personnel, railway personnel, mass-transit security personnel. Vintage clothing related to commercial airlines or other mass-transit may be listed provided that the description clearly states that the item is at least 10 years old, is no longer in use and does not resemble any current uniform.<br />
(b) Manuals or other materials related to commercial transportation, including safety manuals published by commercial airlines and entities operating subways, trains or buses. Such items may only be listed if the description clearly states that the material is obsolete and no longer in use.<br />
(c) Any official, internal, classified or non-public documents.<br />
5.5 Listing of police equipment and associated products are forbidden on both the Wholesaler Marketplace  for Relevant Online Transactions. Where the seller is a properly licensed seller of these products in mainland China, and exception can be made where the sale will not amount to a Relevant Online Transaction.
<br /><br />
6. MEDICAL DRUGS<br />
6.1 The posting of prescription drugs, psychotropic drugs and narcotics is strictly prohibited.<br />
6. 2 The listing or sale of orally administered or ingested sexual enhancement foods and supplements is prohibited.<br />
6. 3 Prescription veterinary drugs may not be listed.<br />
6. 4 Members may post OTC (over-the-counter) drugs on the Paysell.io Website after provision of appropriate production and sales permits to the Website, while transactions of these products are strictly prohibited to be entered into as a Relevant Online Transaction.
<br /><br />
7. MEDICAL DEVICES<br />
Paysell.io does not permit the posting of unauthorized medical devices. Members may only post authorized medical devices after provision of appropriate production and sales permits to the Website, while transactions of these products are strictly prohibited to be entered into as a Relevant Online Transaction.
<br /><br />
8. ADULT AND OBSCENE MATERIALS<br />
8.1 The posting or sale of pornographic materials is strictly prohibited, as it violates laws in many countries. While pornography is difficult to define and standards vary from nation to nation, Paysell.io will generally follow guidelines accepted in Hong Kong and the PRC.<br /><br />
8.2 Items depicting or suggestive of bestiality, rape sex, incest or sex with graphic violence or degradation, and any items depicting or suggestive of sex involving minors, are strictly prohibited.<br />
8.3 In determining whether listings or information should be removed from the Paysell.io Website and the , we consider the overall content of the posting, including images, pictorials, and text.<br />
8.4 While sex toys and related products are permitted to be listed, product descriptions may not include nude or otherwise sexually explicit images.<br /><br />
 
9. CIRCUMVENTION DEVICES AND OTHER EQUIPMENT USED FOR ILLICT PURPOSES<br />
9.1 Descramblers and other items that can be used to gain unauthorized access to television programming (such as satellite and cable TV), internet access, telephone, data or other protected, restricted, or premium services are prohibited. Stating the item is for educational or test purposes will not legitimize a product that is otherwise inappropriate. Some examples of items which are not permitted include smart cards and card programmers, descramblers, DSS emulators and hacking software.<br />
 9.2 Similarly, information on "how to" descramble or gain access to cable or satellite television programming or other services without authorization or payment is prohibited. Paysell.io's policy is to prohibit any encouragement of this type of activity.<br />
 9.3 Devices designed to intentionally block, jam or interfere with authorized radio communications, such as cellular and personal communication services, police radar, global positioning systems (GPS) and wireless networking services (Wi-Fi) are prohibited.<br />
 9.4 The listing or sale of spy equipment and devices used for interception of wire, oral and electronic communications is not permitted on the Site.<br />
9.5 Hidden photographic devices are permitted on the Paysell.io Website, unless used for sexual or illicit purposes.<br />
9.6 Bank card readers and “skimmers” are prohibited from being listed.<br />
9.7 Any and all unauthorized circumvention devices not included in the above are also strictly prohibited.
<br /><br />
10. ILLEGAL SERVICES<br />
10.1 Listings claiming to provide government services and related products are strictly prohibited. Examples include:<br />
(a) Official government-issued identification documents, such as birth certificates, driving licenses, passports and visas;<br />
(b) Completed applications for the abovementioned documents<br />
(c) Any materials, equipment or processes designed for use in the production of government-issued identification documents (e.g. driving license holograms, passport booklets).<br />
(d) The offering for sale or purchase of textile quota is prohibited on the Paysell.io Website and the .<br />
10.2 The listing or sale of any form of invoices or receipts (including blank, pre-filled, or value added invoices or receipts), is strictly prohibited on the Site.<br />
10.3 Paysell.io prohibits listings that offer financial services, including money transfers, issuing bank guarantees and letters of credit, loans, fundraising and funding for person investment purposes, etc.<br />
10.4 Paysell.io prohibits listings for the sole purpose of collecting user information or raising money.<br />
10.5 Listings that offer medical or healthcare services, including services for medical treatment, rehabilitation, vaccination, health checks, psychological counseling, dietetics, plastic surgery and massage are prohibited.<br />
10.6 The posting or sale of bulk email or mailing lists that contain personally identifiable information including names, addresses, phone numbers, fax numbers and email addresses, is strictly prohibited. Also prohibited are software or other tools which are designed or used to send unsolicited commercial email (i.e. "spam").<br />
10.7 Job postings from which a factory/company/institute may directly recruit employees are prohibited on the Site.<br />
10.8 Paysell.io is an online business to business information platform; personal and non-business information is prohibited.<br />
10.9 Non-transferable items may not be posted or sold through the Aibaba.com Website and the . Many items including lottery tickets, airline tickets and some event tickets may not be resold or transferred.
<br /><br />
<br /><br />
11. HUMAN PARTS, HUMAN REMAINS AND PROTECTED FLORA AND FAUNA<br />
11.1 Paysell.io prohibits the listing of human body parts and remains. Examples of such prohibited items include, but are not limited to: organs, bones, blood, sperm, and eggs. Items made of human hair, such as wigs for commercial uses, are permitted.<br />
11.2 The listing or sale of any species (including any animal parts such as pelts, skins, internal organs, teeth, claws, shells, bones, tusks, ivory，logs, roots and other parts) protected by the Convention on International Trade in Endangered Species of Wild Fauna and Flora (CITES) or any other local law or regulation is strictly forbidden on the Paysell.io Website and the .<br />
11.3 The listing or sale of products made with any part of and/or containing any ingredient derived from sharks or marine mammals is prohibited on the Paysell.io Website and the .<br />
11.4 The listing or sale of products made from cats, dogs and bears, as well as any processing equipment, is prohibited on the Paysell.io Website and the .<br />
11.5 The listing or sale of poultry, livestock and pets for commercial purposes is permitted on the Paysell.io Website. For the avoidance of doubt, live animals are prohibited from being listed on the Wholesaler Marketplace and  platforms.
<br /><br />
12. OFFENSIVE MATERIAL AND INFORMATION DETRIMENTAL TO NATIONAL SECURITY<br />
12.1 Any and all publications and other media containing state secrets or information detrimental to national security or public order are prohibited. Such activity can result in your account being delisted.<br />
12.2 Any information supporting or advocating infringement of national sovereignty, terrorist organizations or discrimination on grounds of race, sex, or religion is strictly prohibited on the Paysell.io Website and the . Such activity can result in your account being delisted.<br />
12.3 Postings that are ethnically or racially offensive are prohibited on the Site. Sellers and purchasers must ensure that any wording used portrays appropriate sensitivity to those who might read it in their postings, and when they are offering or purchasing potentially offensive items or services.<br />
12.4 Occasionally, if materials are of historical value or integral to the item (such as a book title), members may use offensive words and phrases such as "Yang Guizi" in the subject and description of a posting. Paysell.io reserves the sole discretion to decide the removal of such items and encourages all members to treat others as they themselves would like to be treated.<br />
12.5 Materials advocating, promoting or otherwise supporting fascism, Nazism and other extreme ideologies are strictly prohibited.<br /><br />
 
13. TOBACCO PRODUCTS<br />
13.1 The posting of tobacco products, including but not limited to cigars, cigarettes, cigarette tobacco, pipe tobacco, hookah tobacco, chewing tobacco and tobacco leaf is prohibited.<br />
13.2 The posting of nicotine and other liquids (e-liquids) for use in electronic cigarettes is forbidden.<br />
13.3 Members located in mainland China may only list equipment used for tobacco processing and production of tobacco products after provision of appropriate production and sales permits to the Website.

            </div>
            <div className='pt-4'>
            <UpOne 
                account={this.props.account} 
                levelOne={this.props.levelOne}
                payLevelOne={this.props.payLevelOne}
                pastPayments={this.props.pastPayments}
            />
            
            </div>


            </SmallLayout>
}
}
 export default Policy2;
