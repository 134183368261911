import { Link, useHistory } from "react-router-dom"
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useState } from "react"
import { paysell } from "../assets"
import ArrowRight from "@mui/icons-material/ArrowRight";
// import {RotatingLines} from "react-loader-spinner";

function Registration() {
    const history = useHistory()
    const auth = getAuth();
    const [clientName, setClientName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");


    const [errClientName, setErrClientName] = useState("");
    const [errEmail, setErrEmail] = useState("");
    const [errPassword, setErrPassword] = useState("");
    const [errCPassword, setErrCPassword] = useState("");
    const [firebaseErr, setFirebaseErr] = useState("");

    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    const handleName=(e)=>{
        setClientName(e.target.value)
        setErrClientName("")
    }

    const handleEmail=(e)=>{
        setEmail(e.target.value)
        setErrEmail("")
    }
    const handlePassword=(e)=>{
        setPassword(e.target.value)
        setErrPassword("")
    }
    const handleCPassword=(e)=>{
        setCPassword(e.target.value)
        setErrCPassword("")
    }

    const emailValidation =(email)=>{
        return String(email).toLowerCase().match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
    }


    const handleRegistration=(e)=>{
        e.preventDefault()
        if(!clientName){
            setErrClientName("Enter your name")
        }
        if(!email){
            setErrEmail("Enter your email")
            setFirebaseErr("")
        }else{
            if(!emailValidation(email)){
                setErrEmail("Enter a valid email")
            }
        }
        if(!password){
            setErrPassword("Enter your password")
        }else{
            if(password.length < 6){
                setErrPassword("Passwords must be at least 6 characters");
            }
        }
        if(!cPassword){
            setErrCPassword("Confirm your password")
        }else{
            if(cPassword !== password){
                setErrCPassword("Password not matched");
            }
        }

        if(clientName && email && emailValidation(email) && password && password.length >=6 && cPassword && cPassword === password){
            setLoading(true)
            createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    updateProfile(auth.currentUser,{
                        displayName:clientName
                    })
                    // const user = userCredential.user;
                    setLoading(false)
                    setSuccessMsg("Account created successfully");
                    setTimeout(()=>{
                        history.push("/signin")
                    },3000)
                    
                })
                .catch((error) => {
                    const errorCode = error.code;
                    if(errorCode.includes("auth/email-already-in-use")){
                        setFirebaseErr("Email already in use, try another one");
                    }
                });
            setClientName("")
            setEmail("")
            setPassword("")
            setCPassword("")
            setFirebaseErr("")
        }
    }
  return (
    <div className="w-full">
        <div className="w-full bg-gray-100 p-10">
            <form className="md:w-3/12 mx-auto flex flex-col items-center">
            <Link to="/"><img className="w-32 pb-4" src={paysell} alt="" /></Link>
                <div className="w-full border border-zinc-200 p-6">
                    <h2 className="font-titleFont text-3xl font-extrabold mb-4">Create Account</h2>
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col gap-2">
                            <p className="text-sm font-medium">Your name</p>
                            <input onChange={handleName}
                            value={clientName}
                            className="w-full  py-1 border border-zinc-400 p-2 text-base rounded-sm outline-none focus-within:border-yellow-600 focus-within:shadow-paysellInput duration-100" type="text" />
                            {
                                errClientName && (
                                    <p className="text-red-600 text-xs font-semibold tracking-wide flex items-center gap-2 -mt-1.5">
                                        <span className="italic font-titleFont font-extrabold text-base">!</span>{" "}{errClientName}</p>
                                )
                            }
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-sm font-medium">Email or mobile phone number</p>
                            <input
                            onChange={handleEmail}
                            value={email}
                            className="w-full lowercase py-1 border border-zinc-400 p-2 text-base rounded-sm outline-none focus-within:border-yellow-600 focus-within:shadow-paysellInput duration-100" type="email" />
                            {
                                errEmail && (
                                    <p className="text-red-600 text-xs font-semibold tracking-wide flex items-center gap-2 -mt-1.5">
                                        <span className="italic font-titleFont font-extrabold text-base">!</span>{" "}{errEmail}</p>
                                )
                            }
                            {
                                firebaseErr && (
                                    <p className="text-red-600 text-xs font-semibold tracking-wide flex items-center gap-2 -mt-1.5">
                                        <span className="italic font-titleFont font-extrabold text-base">!</span>{" "}{firebaseErr}</p>
                                )
                            }
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-sm font-medium">Password</p>
                            <input
                            onChange={handlePassword}
                            value={password}
                            className="w-full  py-1 border border-zinc-400 p-2 text-base rounded-sm outline-none focus-within:border-yellow-600 focus-within:shadow-paysellInput duration-100" type="password" />
                            {
                                errPassword && (
                                    <p className="text-red-600 text-xs font-semibold tracking-wide flex items-center gap-2 -mt-1.5">
                                        <span className="italic font-titleFont font-extrabold text-base">!</span>{" "}{errPassword}</p>
                                )
                            }
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-sm font-medium">Re-enter Password</p>
                            <input
                            onChange={handleCPassword}
                            value={cPassword}
                            className="w-full  py-1 border border-zinc-400 p-2 text-base rounded-sm outline-none focus-within:border-yellow-600 focus-within:shadow-paysellInput duration-100" type="password" />
                            {
                                errCPassword && (
                                    <p className="text-red-600 text-xs font-semibold tracking-wide flex items-center gap-2 -mt-1.5">
                                        <span className="italic font-titleFont font-extrabold text-base">!</span>{" "}{errCPassword}</p>
                                )
                            }
                            <p className="text-xs text-gray-600">Password must be at least 6 characters</p>
                        </div>
                        <button onClick={handleRegistration}  className="w-full p-2.5 text-sm font-normal rounded-sm bg-gradient-to-t from-yellow-200 to-yellow-400 hover:bg-gradient-to-b border border-zinc-400 active:border-yellow-800 active:shadow-paysellInput">Continue</button>
                        {
                            loading && (
                                <div className="flex justify-center">
                                    Loading
                                    {/* <RotatingLines
                                    strokeColor="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="50"
                                    visible={true}
                                    /> */}
                                </div>
                            )
                        }
                        {
                            successMsg && (
                                <div>
                                    <p className="flex justify-center">{successMsg}</p>
                                </div>
                            )
                        }
                    </div>
                <p className=" text-xs pt-2">Already have an account <span className=" text-blue-600"> <Link to="/signin">Sign in  <ArrowRight /></Link></span></p>
                </div>
            </form>
        </div>
    </div>
  )
}

export default Registration