import { Link } from "react-router-dom"
import Layout from "./components/Layout";

const NotFound = () => {
  return (
    <Layout>
      <h2>Sorry</h2>
      <p>That page cannot be found</p>
      <Link to="/">Back to the homepage...</Link>
    </Layout>
  );
}
 
export default NotFound;