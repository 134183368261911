import { Link, useHistory } from "react-router-dom"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { paysell } from "../assets"
import ArrowRight from "@mui/icons-material/ArrowRight"
import { useState } from "react"
import { useDispatch} from "react-redux"
import { setUserInfo } from "../redux/slice";

function Signin() {
    const auth = getAuth();
    const history = useHistory()
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [errEmail, setErrEmail] = useState("");
    const [errPassword, setErrPassword] = useState("");

    const [userEmailErr, setUserEmailErr] = useState("")
    const [userPasswordErr, setUserPasswordErr] = useState("")

    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    const handleEmail=(e)=>{
        setEmail(e.target.value)
        setErrEmail("")
    }
    const handlePassword=(e)=>{
        setPassword(e.target.value)
        setErrPassword("")
    }


    const handleLogin=(e)=>{
        e.preventDefault()
        
        if(!email){
            setErrEmail("Enter your email")
        }
        
        if(!password){
            setErrPassword("Enter your password")
        }
        if(email && password){
            setLoading(true)
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    dispatch(setUserInfo({
                        _id:user.id,
                        userName:user.displayName,
                        email:user.email
                    }))

                    setLoading(false)
                    setSuccessMsg("Logged in successfully! Welcome back.");
                    setTimeout(()=>{
                        history.push("/store")
                    }, 2000)
                })
                .catch((error) => {
                    setLoading(false);
                    const errorCode = error.code;
                    if (errorCode.includes("auth/invalid-email")) {
                        setUserEmailErr("Invalid email")
                    }
                    if (errorCode.includes("auth/wrong-password")){
                        setUserPasswordErr("Wrong password! try again");
                    }
                });

            setEmail("")
            setPassword("")
        }
        }
  return (
    <div className="w-full">
        <div className="w-full bg-gray-100 p-10">
            {successMsg ? (
                <div className="w-full flex justify-center items-center py-32">
                    <p className="text-lg font-semibold px-6 py-2">
                        {successMsg}
                    </p>

                </div>)
            : (
                <form className="w-3/12 mx-auto flex flex-col items-center">
                <Link to="/"><img className="w-32 pb-4" src={paysell} alt="" /></Link>
                <div className="w-full border border-zinc-200 p-6">
                    <h2 className="font-titleFont text-3xl font-medium mb-4">Sign in</h2>
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col gap-2">
                            <p className="text-sm font-medium">Email or mobile phone number</p>
                            <input
                            onChange={handleEmail}
                            value={email}
                            className="w-full lowercase py-1 border border-zinc-400 p-2 text-base rounded-sm outline-none focus-within:border-yellow-600 focus-within:shadow-paysellInput duration-100" type="email" />
                            {
                                errEmail && (
                                    <p className="text-red-600 text-xs font-semibold tracking-wide flex items-center gap-2 -mt-1.5">
                                        <span className="italic font-titleFont font-extrabold text-base">!</span>{" "}{errEmail}</p>
                                )
                            }
                            {
                                userEmailErr && (
                                    <p className="text-red-600 text-xs font-semibold tracking-wide flex items-center gap-2 -mt-1.5">
                                        <span className="italic font-titleFont font-extrabold text-base">!</span>{" "}{userEmailErr}</p>
                                )
                            }
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-sm font-medium">Password</p>
                            <input
                            onChange={handlePassword}
                            value={password}
                            className="w-full lowercase py-1 border border-zinc-400 p-2 text-base rounded-sm outline-none focus-within:border-yellow-600 focus-within:shadow-paysellInput duration-100" type="password" />
                            {
                                errPassword && (
                                    <p className="text-red-600 text-xs font-semibold tracking-wide flex items-center gap-2 -mt-1.5">
                                        <span className="italic font-titleFont font-extrabold text-base">!</span>{" "}{errPassword}</p>
                                )
                            }
                            {
                                userPasswordErr && (
                                    <p className="text-red-600 text-xs font-semibold tracking-wide flex items-center gap-2 -mt-1.5">
                                        <span className="italic font-titleFont font-extrabold text-base">!</span>{" "}{userPasswordErr}</p>
                                )
                            }
                        </div>
                        <button onClick={handleLogin} className="w-full p-2.5 text-sm font-normal rounded-sm bg-gradient-to-t from-yellow-200 to-yellow-400 hover:bg-gradient-to-b border border-zinc-400 active:border-yellow-800 active:shadow-paysellInput">Continue</button>
                        {
                            loading && (
                                <div className="flex justify-center">
                                    Loading
                                    {/* <RotatingLines
                                    strokeColor="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="50"
                                    visible={true}
                                    /> */}
                                </div>
                            )
                        }
                        {
                            successMsg && (
                                <div>
                                    <p className="flex justify-center">{successMsg}</p>
                                </div>
                            )
                        }
                    </div>
                    <p className="text-xs text-black leading-4 mt-4">By Continuing, you agree to Paysell's <span className="text-blue-600">Conditions of Use</span> and <span className="text-blue-600">Privacy Notice.</span></p>
                    <p className="text-xs text-gray-600 mt-4 cursor-pointer group"><ArrowRight /> <span className="text-blue-600 group-hover:text-yellow-700 group-hover:underline">Need help?</span></p>
                </div>
                <p className="w-full text-xs text-gray-600 mt-4 flex items-center">
                    <span className="w-1/3 border border-1  bg-gray-400 inline-flex"></span>
                    <span className="w-1/3 text-center"> New to Paysell?</span>
                    <span className="w-1/3 border border-1  bg-zinc-400 inline-flex"></span>
                </p>
                <Link to='/registration' className="w-full">
                    <button className="w-full p-2.5 mt-4 text-sm font-normal rounded-sm bg-gradient-to-t bg-gray-200 from-200 to-100 border border-zinc-400 active:shadow-paysellInput">
                        Create your Paysell account
                    </button>
                </Link>
            </form>
            )}
        </div>
        <div className="w-full bg-gradient-to-t from-white via-white to-zinc-200 flex flex-col gap-4 justify-center items-center py-10">
            <div className="flex items-center gap-6">
                <p className="text-xs text-blue-600 hover:text-yellow-600 hover:underline underline-offset-1 cursor-pointer duration-100">
                    Conditions of Use
                </p>
                <p className="text-xs text-blue-600 hover:text-yellow-600 hover:underline underline-offset-1 cursor-pointer duration-100">
                    Privacy Notice
                </p>
                <p className="text-xs text-blue-600 hover:text-yellow-600 hover:underline underline-offset-1 cursor-pointer duration-100">
                    Conditions of Use
                </p>
            </div>
        </div>
    </div>
  )
}

export default Signin