import React from 'react'

export default function Cost({quantity}) {
  return (
    <div>
        <div className='p-2'>
            <p className="float-left p-2 font-thin text-sm">
                Price:
            </p> 
            <p>
               {quantity}
            </p>
        </div>
    </div>
  )
}
