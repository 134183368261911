import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function BreadCrumbs({category}) {
    return (
        <Breadcrumb>
            <Breadcrumb.Item href="/store">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>{category}</Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default BreadCrumbs
