// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyAZpJzHFjxu6zIQGYbjHKLF5KVYEBYLcL4",
  authDomain: "paysell-912a1.firebaseapp.com",
  databaseURL: "https://paysell-912a1-default-rtdb.firebaseio.com",
  projectId: "paysell-912a1",
  storageBucket: "paysell-912a1.appspot.com",
  messagingSenderId: "981036757116",
  appId: "1:981036757116:web:6b250a00394baf9b7599f9",
  measurementId: "G-PMCH4VPLM8"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export default firebaseConfig