
import Layout from '../Layout';
import Beverages from '../feeds/store/Beverages';
import Produce from '../feeds/store/Produce';
import SnackFoods from '../feeds/store/SnackFoods';
import BreadsBakery from '../feeds/store/BreadsBakery';
import BreakfastFoods from '../feeds/store/BreakfastFoods';
import DairyCheeseEggs from '../feeds/store/DairyCheeseEggs';
import Frozen from '../feeds/store/Frozen';
import MeatSeafood from '../feeds/store/MeatSeafood';
import PantryStaples from '../feeds/store/PantryStaples';


export default function Store({account,businessRegistration, daiBalance, dict, cartItems, categories, ...props}) {
        return (
         <Layout account={account}
            businessRegistration={ businessRegistration} daiBalance={daiBalance}  dict={dict} cartItems={cartItems} sponser={props.sponser}> 
                <div className='pt-4 pr-10 pl-10'>
                    <img src='https://images-na.ssl-images-amazon.com/images/G/01/composer/uploads/FreshStoreMultitile/SVGMemorialDayProduct_yrkdqg4.jpg' alt='' />
                </div>
                <div className='pt-4 pr-10 pl-10'>
                    <div className='bg-white rounded-sm p-4  h-96'>
                        <Produce  products={props.products} title={`Produce`} />
                    </div>
                </div>
                <div className='pt-4 pr-10 pl-10'>
                    <div className='bg-white rounded-sm p-4  h-96'>
                        <SnackFoods  products={props.products} title={`Snack Foods`} />
                    </div>
                </div>
                <div className='pt-4 pr-10 pl-10'>
                    <div className='bg-white rounded-sm p-4 h-96'>
                        <Frozen  products={props.products} title={`Frozen`} />
                    </div>
                </div>

                <div className='pt-4 pr-10 pl-10'>
                    <div className='bg-white rounded-sm p-4 h-96'>
                        <PantryStaples  products={props.products} title={`Pantry Staples`} />
                    </div>
                </div>
                <div className='pt-4 pr-10 pl-10'>
                    <div className='bg-white rounded-sm p-4 h-96'>
                        <MeatSeafood  products={props.products} title={`Meat & Seafood`} />
                    </div>
                </div>
                <div className='pt-4 pr-10 pl-10'>
                    <div className='bg-white rounded-sm p-4 h-96'>
                        <BreadsBakery  products={props.products} title={`Breads & Bakery`} />
                    </div>
                </div>
                <div className='pt-4 pr-10 pl-10'>
                    <div className='bg-white rounded-sm p-4 h-96'> 
                        <Beverages products={props.products} title={`Beverages`} />
                    </div>
                </div>
                <div className='pt-4 pr-10 pl-10'>
                    <div className='bg-white rounded-sm p-4 h-96'>
                        <DairyCheeseEggs  products={props.products} title={`Dairy, Cheese & Eggs`} />
                    </div>
                </div>
                <div className='pt-4 pr-10 pl-10'>
                    <div className='bg-white rounded-sm p-4 h-96'>
                        <BreakfastFoods  products={props.products} title={`Breakfast Foods`} />
                    </div>
                </div>
            </Layout>
        )
    }