import React from 'react';
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom';

export default function SellerInfo({account, seller, businessRegistration, profile, handleContact}) {
    return (
        <div> 
            
                {businessRegistration.filter(i => i.rootid === seller).map(rw => {
                    return ( 
                    <Card className='p-3'> 
                        <p>
                            <p className='font-bold'>{rw.companyName}</p>
                            <p className='text-xs pb-2'>Trading Company</p>
                            <div className='flex'>
                                <div className='flex-1'>
                                    <p className='text-sm'>Total Staff</p>
                                    <p className='font-base font-bold text-gray-500 text-lg'>
                                        {rw.numberOfEmployees}
                                    </p>
                                    <div>
                                        <p className='flex text-sm pt-3 pb-2'><strong><a href={`https://wa.me/${rw.mobile}`} target="_blank" rel="noreferrer"><img src='/img/logos/whatsapp.png' className='w-7' alt='whatsapp'/>
                                        </a></strong> {rw.mobile}</p>
                                        <p className='text-sm  pb-2'>{rw.email}</p>
                                        <p className='text-sm'>{rw.city}</p>
                                        <p className='text-sm'>{rw.country}</p>
                                        </div>
                                        
                                </div>
                                <div className='flex-1'>
                                <p className='text-sm p-2'>
                                    { account ?
                                        <div onClick={handleContact} className='cursor-pointer'>
                                            Contact Seller
                                        </div>
                                        : 
                                        <></>
                                    }
                                    <Link to={`/shop/${seller}`} >Visit Store</Link> <br />
                                </p>
                                </div>
                            </div>
                            <div>
                                
                            </div>
                        </p>
                    </Card>
                    )  })}
           
        </div>
    ) 
}
