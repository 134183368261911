import React, { Component } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form'
import { Button,  Input} from "reactstrap";

class AddListingPics extends Component {
  state = {
    status: null,
    rootid: null,
    img: null
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  };

  handleImageChange = (e) => {
    this.setState({
      img: e.target.files[0]
    })
  };
  
  componentDidMount(){
    this.setState({
        rootid: this.props.rootid,
        status: "Active"
    })
}

  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(this.state);
    let form_data = new FormData();
    form_data.append('img', this.state.img, this.state.img.name);
    form_data.append('rootid', this.state.rootid);
    form_data.append('status', this.state.status);
    let url = 'https://paysell.app/ads/images/';
    axios.post(url, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
        .then(res => {
          console.log(res.data);
          window.location="/success/"
        })
        .catch(err => console.log(err))
  };

  render() {
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <p>
            <Input type="hidden" id='status' value={this.state.status} onChange={this.handleChange} required/>
          </p>
          <p>
            <Input type="hidden" id='rootid' value={this.state.rootid} onChange={this.handleChange} required/> 
          </p>
          <p>
            <Input type="file"
                   id="img"
                   accept="image/png, image/jpeg"  onChange={this.handleImageChange} required/>
          </p>
          <p className='pt-4'>
             <Button> Submit</Button>
          </p>
        </Form>
      </>
    );
  }
}

export default AddListingPics;
