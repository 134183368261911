
import Layout from '../Layout';
import Faqs from './Faqs';

export default function Home({account,businessRegistration,sponser, noSponser, daiBalance, dict, cartItems, categories, ...props}) {
        return (
         <Layout account={account}
            businessRegistration={ businessRegistration} daiBalance={daiBalance}  dict={dict} cartItems={cartItems} sponser={sponser} noSponser={noSponser}> 
                <div className='pt-4 pr-10 pl-10'>
                    <img src='https://images-na.ssl-images-amazon.com/images/G/01/composer/uploads/FreshStoreMultitile/steak_salad_hero_beef_q1v2_n7thz77.jpg' alt='' />
                </div>
                <div className='max-w-3xl mx-auto text-center pt-10'>
                    <div className='text-5xl font-thin'>
                        Paysell Online Store
                    </div>
                    {sponser !== noSponser ? (
                    <div className='flex w-full justify-center p-10'>
                        <div className='p-1'><a href="/store"><button className='bg-yellow-400 w-64 p-1 rounded-sm'>Shop Groceries Online</button></a></div>
                        <div className='p-1'><a href="/classifieds"><button className='border border-gray-50 p-1 rounded-sm w-64'>Classifieds</button></a></div>
                    </div>)
                    : <div className='pt-10'></div>
                    }
                    <div className='text-center'>
                    Paysell is an online grocery store and classifieds platform for Zimbabwean market. We offer low prices on everyday favorites, plus same-day delivery and pickup in select locations for our members.
                    <br /> <br />
                    {sponser === noSponser ? ( <div>
                     Click here to <a href='/register' className=' font-bold uppercase'>become a Member.</a></div>)
                     : <></>
                    }
                    </div>

                    <div className='p-10 font-bold text-xl'>Frequently Asked Questions</div>

                    <Faqs title={`What is Paysell and how does it work as an online grocery store that accepts USDT?`} description={`Paysell is an online grocery store that accepts USDT (Tether) as a payment method. USDT is a stablecoin pegged to the value of the US dollar, providing a convenient and secure way for customers to make purchases on our platform.`} />
                    <Faqs
                         title={`How can I place an order on Paysell in Zimbabwe?`} description={`To place an order on Paysell in Zimbabwe, simply visit our website or use our mobile app. Browse through our wide range of grocery products, select the items you need, and add them to your cart. Once you have finished shopping, proceed to the checkout page, where you can review your order and provide the necessary delivery details.`}
                    />
                    <Faqs
                         title={`Is Paysell a membership only online store?`} description={`Yes Paysell is a membership only platform and we charge 9.99 USDT annual fee.`}
                    />
                    <Faqs
                         title={`What is the delivery process for orders placed on Paysell?`} description={`We offer doorstep delivery for orders placed on Paysell in Zimbabwe. After you have successfully placed your order and provided the delivery details, our team will process and pack your items. Delivery times may vary based on your location, but we strive to deliver your groceries as promptly as possible.`}
                    />
                    <Faqs
                         title={`Is Paysell available for customers in Zimbabwe only?`} description={`Yes, Paysell is currently only available to customers in Zimbabwe. But intends to go regional in the near future, offering a convenient and hassle-free shopping experience.`}
                    />
                    <Faqs
                         title={`What are the benefits of using USDT to pay for groceries on Paysell?`} description={`Using USDT as a payment method on our online grocery store offers several benefits. Firstly, it provides a secure and decentralized payment option, leveraging the advantages of blockchain technology. Secondly, USDT's stability to the US dollar helps to mitigate the volatility associated with other cryptocurrencies, ensuring consistent purchasing power. Lastly, using USDT eliminates the need for traditional banking systems, reducing transaction costs and potential delays often encountered with fiat currency payments.`}
                    />
                </div>
                
                
            </Layout>
        )
    }