import React, { Component } from 'react';
import { Form, Message, Input, Button } from 'semantic-ui-react';
import SmallLayout from './components/shop/SmallLayout';
import FaqsRegistration from './components/dashboard/FaqsRegistration';

class Register extends Component {
  state = {
      inviter: '',
      subscription: null,
      loading: false,
      errorMessage: ''
  };

  componentDidMount(){
      this.setState({
        //   subscription: '0x7B027aC937b6a37F017190753902983C48a70196',
          subscription: '0x055CCde35b07b354aa38818e2c3C78d8bBcA2dd8'
      })
  }
  
  onSubmit= async (event) => {
      event.preventDefault()

      this.setState({ loading: true, errorMessage: '' });
      try {
      
          let Amount = window.web3.utils.toWei('0.001610', 'Ether')
          this.props.registerAccount(this.state.inviter, this.state.subscription, Amount)
          
      } catch (err) {
          this.setState({ errorMessage: err.message });
      }
  }
  render() { 
      return ( 
            <SmallLayout account={this.props.account} sponser={this.props.sponser} noSponser={this.props.noSponser}>
              <div className="w-full pt-10 rounded-lg bg-white sm:p-10 border m-2 border-gray-200 hover:bg-gray-50">
                
                  <div className="flex-1 p-10">
                    <div className='text-2xl md:font-semibold md:text-2xl p-1 text-center mb-6'>Lets have you <i><b>JOIN</b></i> our COMMUNITY!</div>
                    <div className='text-center'>Paysell is an online platform for <b>Hard</b> and <b>Soft</b> commodities , that is use innovation to sell and develop markets for Global producers.</div>
                    <div className='p-1'></div>
                    {this.props.sponser === this.props.noSponser ? (
                    <></>
                    )
                    :   <div className='pt-2'>
                    <Form onSubmit={this.onSubmit} error={!!this.state.errorMessage}>
                        <Form.Field>
                            <Input
                                value={this.state.inviter}
                                onChange={event => this.setState({ inviter: event.target.value })}  
                                placeholder='Enter the public address of the person who referred you to Paysell.'
                            />
                        </Form.Field>
                        <div className="text-center">
                            <div className="mx-auto text-center">
                            <Button loading={this.state.loading}  color='yellow' content='Yellow'>
                            Join The Community
                            </Button>
                            </div>
                            <Message error header="Oops!"  content='Please enter address of referrer. And make sure you are logged in with Metamask before registering.'/>
                        </div>
                        <Form.Field>
                            <Input
                                size='md'
                                value={this.state.subscription}
                                onChange={event => this.setState({ subscription: event.target.value })}  
                                type="hidden"
                                
                            />
                        </Form.Field>
                    </Form>
                </div>
                    }
                    <FaqsRegistration
                         title={`Why registration is needed?`} description={`As part of our effort to protect all our users, Paysell makes membership mandatory. To maintain your membership we charge 15.99 USDT once off fee.`}
                    />
                  </div>
                  <div className="flex-1">
                  <img src="../img/banner/3.jpeg" alt="" className='' />
                </div>
              </div>
              </SmallLayout>
      )
  }
}
export default Register;



