import React, { useEffect, useState, useRef } from 'react';
import { getAuth, signOut } from "firebase/auth";
import { Link } from 'react-router-dom';
// import MyPaySell from '../feeds/MyPaySell';
import { paysell } from '../../assets/index';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import SearchIcon from '@mui/icons-material/Search';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { allItems } from '../../constants';
import { useDispatch, useSelector } from 'react-redux'; 
import HeaderBottom from './HeaderBottom';
import { LogoutIcon } from '@heroicons/react/outline';
import { userSignOut } from '../../redux/slice';

const Header = ({ account, daiBalance, businessRegistration }) => {
    const ref = useRef();
    const auth = getAuth();
    const dispatch = useDispatch()
    const [showAll, setShowAll] = useState(false)
    const products = useSelector((state)=>state.paysell.products);
    const userInfo = useSelector((state)=> state.paysell.userInfo);
    
    const handleLogout=()=>{
      signOut(auth).then(() => {
        dispatch(userSignOut())
      }).catch((error) => {
        // An error happened.
      });
    }
    useEffect(() => {
      document.body.addEventListener("click", (e) => {

      })
    }, [ref, showAll])
  return (
    <div className='md:w-5/6 md:mx-auto pb-10'>
      <div className=" h-40 text-white px-4 py-3 flex items-center gap-4">
        <div className=" headerHover rounded-tl-sm rounded-tr-sm">
          <Link to="/">
            <img src={paysell} alt='Paysell' className='w-36 pt-2' />
          </Link>
        </div>
        
        <div className='hidden h-10 rounded-md md:flex md:flex-grow md:relative md:w-6/12'>
          <span onClick={()=>setShowAll(!showAll)} className='h-full bg-gray-200 hover:bg-gray-300 border-2 cursor-pointer duration-300 text-sm text-paysell_dark font-titleFont flex items-center justify-center rounded-md pl-2 pr-2'><span><MenuOpenIcon /></span>{" "} All Categories 
          </span>
            { showAll && (
                <div>
                    <ul className='absolute w-56 h-52 top-10 left-0 overflow-y-scroll overflow-x-hidden bg-white border-[1px] border-paysell_dark text-black p-2 flex-col gap-1 z-50'>
                        {allItems.map((item)=>
                        <li key={item._id} className='text-sm tracking-wide font-titleFont border-b-[1px] border-b-transparent hover:border-b-paysell_dark p-1 cursor-pointer duration-200 '>{item.title}</li>
                        )}
                    </ul>
                </div>
            )

            }

          {/* <input type='text' className='h-full text-base text-paysell_dark flex-grow outline-none border-none px-2' /> */}
          {/* <span className='w-12 h-full flex items-center justify-center bg-paysell_yellow hover:bg-[#f3a847] duration-300 text-paysell_dark cursor-pointer rounded-tr-md rounded-br-md'>
            <SearchIcon />
          </span> */}
        </div>
        <div className='flex flex-col items-start justify-center headerHover text-gray-400'>
            <a href='/signin' className='text-xs  font-light'>
              {
                userInfo ? (
                  <p className='text-xs text-paysell_dark font-light'>
                    {userInfo.userName}
                  </p>
                ) : (
                  <p className='text-xs text-paysell_dark font-light'>
                    Hello, sign in
                  </p>
                )
              }
            </a>
            <p className='text-sm font-semibold -mt-1'>Accounts & Lists{" "}
            <span>
                <ArrowDropDownIcon />
            </span>
            </p>
        </div>
        <div className='hidden md:flex md:flex-col items-start justify-center headerHover text-gray-400'>
            <p className='text-xs text-paysell_dark font-light'>Returns</p>
            <p className='text-sm font-semibold -mt-1'>& Orders
            </p>
        </div>
        {userInfo && (
             <div onClick={handleLogout} className='flex  justify-center items-center headerHover relative bg-gray-400'>
                <LogoutIcon /><br />
                <p className='text-xs font-semibold mt-3 text-whiteText'><span className='absolute text-xs -top-1 left-14 font-semibold p-1 h-4 bg-gray-900 text-white rounded-full flex justify-center items-center '>{products.length > 0 ? products.length : '0' }</span></p>
                <p className='hidden md1:inline-flex text-xs font-semibold text-whiteText'>Log Out</p>
             </div>
        )
       
        }
        {/* <div className="text-gray-400 link flex items-center">
          {account &&
            <MyPaySell account={account} daiBalance={daiBalance} businessRegistration={businessRegistration} />
          }
        </div> */}
      </div>
      <HeaderBottom />
    </div>
  );
};

export default Header;
