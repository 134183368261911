import React from 'react';
import { Link } from 'react-router-dom';

export default function AllCategories({categories}) {
    return (
        <div className='p-4'>
            <div className='text-2xl p-2'>All Categories</div>
            {categories.sort((a, b) => a.id > b.id ? 1:-1).map((category, key) => (
                    <div key={key} className='p-2'>
                        <Link to={`/${category.name}`}>{category.name}</Link>
                    </div>
                ))}
        </div>
        
    )
}
