import React from 'react'
import SmallLayout from '../../shop/SmallLayout';

export default function ShopSummary({ account, businessRegistration}) {
        
    return (
        <SmallLayout account={account}>
            <div className='pt-10'>
                <p className='text-4xl p-2'>Seller Center</p>
                <p className='p-3'>
                    <hr />
                </p>
                <p className='p-2'>
                {businessRegistration.filter(i => i.rootid === account).map(rw => {
                    return ( <>
                        <div className='flex'>
                            <div className='sm:w-1/4 text-right p-2 font-bold'>Shop Name : </div>
                            <div className='w-2/4'>
                                <p className='p-2'>
                                    {rw.companyName}
                                </p>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='sm:w-1/4 text-right p-2 font-bold'>City :</div>
                            <div>
                                <p className='p-2'>
                                    {rw.city}
                                </p>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='sm:w-1/4 text-right p-2 font-bold'>Country :</div>
                            <div>
                                <p className='p-2'>
                                    {rw.country}
                                </p>
                            </div>
                        </div>

                        <div className='flex'>
                            <div className='sm:w-1/4 text-right p-2 font-bold'>Shop Owner :</div>
                            <div className='w-2/4'>
                                <p className='p-2'>
                                   {rw.companyOwner}
                                </p>
                            </div>
                        </div>

                        <div className='flex'>
                            <div className='sm:w-1/4 text-right p-2 font-bold'>Mobile :</div>
                            <div>
                                <p className='p-2'>
                                    {rw.mobile}
                                </p>
                            </div>
                        </div>

                        <div className='flex'>
                            <div className='sm:w-1/4 text-right p-2 font-bold'>Email :</div>
                            <div>
                                <p className='p-2'>
                                    {rw.email}
                                </p>
                            </div>
                        </div>

                        <div className='flex'>
                            <div className='sm:w-1/4 text-right p-2 font-bold'>Number of staff :</div>
                            <div>
                                <p className='p-2'>
                                    {rw.numberOfEmployees}
                                </p>
                            </div>
                        </div>
                        </> 
                )  })}
                    </p>
                    <p className='p-3'>
                        <hr />
                    </p>
                    <div className='flex'>

                    <div className='p-2'>
                        <a href="/add-product" className='h-4 w-4 bg-yellow-400 text-center p-2 rounded-full text-black font-bold'>Post Advert</a>
                    </div>
                        <div className='p-2'>
                            <a href="/" className='h-4 w-4 bg-gray-400 text-center p-2 rounded-full text-black font-bold'>Go Home</a>
                        </div>     
                    </div>
            </div>

        </SmallLayout>
    )
  }
