import React from 'react';
import { Image } from 'react-bootstrap';

export default function ListingAttributesModal({product, listing}) {
    return (
        <>
            {listing.filter(rw => rw.id === product).map(list => {
                return (
                    <div>
                        <Image src={`${list.image}`}  width={400} height={300} rounded />
                    </div>
                )
            })}
        </>
    )
}
