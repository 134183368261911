import React, { Component  } from 'react';
import axios from "axios";
import Form from 'react-bootstrap/Form'
import { Button,  Input} from "reactstrap";

export default class AddProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            price: null,
            category: null,
            moq: null,
            description: null,
            owner: null,
            measurementType: null,
            image: null,
            status: null,
          };
        }
        componentDidMount(){
            this.setState({
                status: "Active" 
            })
        }

      onChange = (e) => {
        let { id, value } = e.target;
        this.setState({ [id]: value })
    }

    handleImageChange = (e) => {
        this.setState({
          image: e.target.files[0]
        })
      };

    handleSubmit = async (e) => {
        e.preventDefault();
        let form_data = new FormData();
        form_data.append('image', this.state.image, this.state.image.name);
        form_data.append('title', this.state.title);
        form_data.append('price', this.state.price);
        form_data.append('category', this.state.category);
        form_data.append('moq', this.state.moq);
        form_data.append('description', this.state.description);
        form_data.append('owner', this.props.account);
        form_data.append('measurementType', this.state.measurementType);
        form_data.append('status', this.state.status);
        let url = `https://paysell.app/ads/products/`;
        axios.post(url, form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          }).then(res => {
            console.log(res.data);
            window.location="/product-upload-success/"
          })
          .catch(err => console.log(err))
        }
    
    render() {
    return ( 
        <div>
            <div className='font-bold text-2xl sm:text-4xl pt-8 pb-4'>Add product</div>
            <div>Post details of your product here</div>
            
                    <p className='p-1'>
                    <Input
                        id="title"
                        type="text"
                        onChange={this.onChange}
                        value={this.state.title}
                        placeholder='Product Title'
                        required
                    />
                    </p>
                    <p className='p-1'>
                    <Form.Control 
                        as="textarea" 
                        rows={5}
                        id="description"
                        onChange={this.onChange}
                        value={this.state.description} 
                        required
                    />
                    </p>
                    <p className='p-1'>
                <Input
                    id="price"
                    type="number"
                    onChange={this.onChange}
                    value={this.state.price}
                    placeholder='Selling Price'
                    required
                />
                </p>
                <p className='p-1'>
                    <select id="category" className='form-control' onChange={this.onChange}
                        value={this.state.category} required>
                            <option value="">Select Category</option>
                        {this.props.dict.filter(rw => rw.category === 'Store').map(rw => (
                            <option value={rw.name} key={rw.id}>{rw.name}</option>
                        ))}
                    </select>
                </p>
                <p className='p-1'>
                    <Input type="file"
                        id="image"
                        accept="image/png, image/jpeg"  onChange={this.handleImageChange} required/>

                </p>
                <p className='p-1 text-xs'>Upload product image</p>
                <p className='p-1'>
                    <Input
                        id="moq"
                        type="number"
                        onChange={this.onChange}
                        value={this.state.moq}
                        placeholder='Minimum Order Quantity'
                        required
                    />
                </p>
                <p className='p-1'>
                    <select id="measurementType" className='form-control' onChange={this.onChange}
                        value={this.state.measurementType} required>
                            <option value="">Select Measurement Type</option>
                        {this.props.dict.filter(rw => rw.category === 'measurementType').map(rw => (
                            <option value={rw.name} key={rw.id}>{rw.name}</option> 
                        ))}
                    </select>
                </p>
                <p className='p-1'>
                    <Input
                        id="owner"
                        type="hidden"
                        onChange={this.onChange}
                        value={this.props.account}
                    />
                </p>
                <p className='p-1'>
                    <Input
                        id="status"
                        type="hidden"
                        onChange={this.onChange}
                        value={this.state.status}
                    />
                </p>
                <br />
                <div className='text-center'>
                <Button variant='warning' onClick={this.handleSubmit}>Submit AD</Button>
                </div>
            
        </div>
    )
}
}
