import React, {useState} from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'

import ListingAttributesModal from './ListingAttributesModal';
import SimilarProduct from '../../shop/SimilarProduct';

export default function NewArrivals({products, category, ...props}) {
    const [product, setProduct] = useState();

    const displayProducts = products.slice(0,3).sort((a, b) => a.id > b.id ? 1:1).map((product, key) => {
        return (   
            <div className='p-2'>
                <div key={product.id}>
                    <SimilarProduct
                    id={product.id} 
                    title={product.title} 
                    category={product.category} 
                    image={product.image} price={product.price}
                    product={product}
                    addToCart={props.addToCart}
                    />
                </div>
            </div>
                
            )
        })
        
        return (
                <>
                    <div className='font-bold flex text-lg'><img src='/img/icons/new-icon.png' alt='' width={35} className='mr-1 p-1 border-gray-100 border-2' />New Arrivals</div>
                    <div className="grid grid-flow-row-dense md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                        {displayProducts}
                    </div>
                    
                    {product && (
                        <Modal
                            show={product}
                            size="lg"
                            onHide={() => setProduct(false)}
                            centered
                        >
                            <Modal.Body>
                                <Row>
                                    <Col>
                                    <ListingAttributesModal listing={products} product={product.id} />
                                    </Col>
                                    <Col>
                                    <h1>{product.name}</h1>
                                    <div class="capitalize text-sm  text-gray-500  font-serif">
                                        {product.category}
                                    </div>
                                    </Col>
                                </Row>
                                </Modal.Body>
                        </Modal>
                        )}
                </>
            )   
}

