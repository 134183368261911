import React, { Component } from 'react'
import SmallLayout from '../shop/SmallLayout'
import axios from "axios";
import Form from 'react-bootstrap/Form'
import { Button,  Input} from "reactstrap";

export default class UploadProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            lname: '',
            mobile: '',
            email: '',
            owner: null,
            status: null,
          };
        } 
        componentDidMount(){
            this.setState({
                owner: this.props.account,
                status: "Active"
            })
        }
        onChange = (e) => {
            let { name, value } = e.target;
            this.setState({ [name]: value })
        }
        handleFormSubmit = (e) => {
            e.preventDefault();
            let data = new FormData();
            data.append('fname', this.state.fname);
            data.append('lname', this.state.lname);
            data.append('mobile', this.state.mobile);
            data.append('email', this.state.email);
            data.append('owner', this.state.owner);
            data.append('status', this.state.status);
            let url = 'https://paysell.app/api/add_profile/';
            axios.post(url, data)
                .then(res => {
                console.log(res.data);
                window.location="/success/"
                })
                .catch(err => console.log(err))
            }

  render() {
    return (
        <SmallLayout account={this.props.account}>
           
          <Form onSubmit={
                this.handleFormSubmit}>
                    <p className='p-1'>
                        <Input
                            name="fname"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.fname}
                            placeholder='First Name'
                        />
                    </p>
                    <p className='p-1'>
                        <Input
                            name="lname"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.lname}
                            placeholder='Last Name'
                        />
                    </p>

                    <p className='p-1'>
                        <Input
                            name="mobile"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.mobile}
                            placeholder='Phone'
                        />
                    </p>

                    <p className='p-1'>
                        <Input
                            name="email"
                            type="email"
                            onChange={this.onChange}
                            value={this.state.email}
                            placeholder='Email'
                        />
                    </p>

                    <p className='p-1'>
                        <Input
                            name="owner"
                            type="hidden"
                            onChange={this.onChange}
                            value={this.state.owner}
                        />
                    </p>
                    <p className='p-1'>
                        <Input
                            name="status"
                            type="hidden"
                            onChange={this.onChange}
                            value={this.state.status}
                        />
                    </p>
                    <p className='pt-4'>
                        <Button> Submit</Button>
                    </p>

          </Form>
        </SmallLayout>
    )
  }
}
