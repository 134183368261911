import React, { Component } from 'react';
import SmallLayout from '../components/shop/SmallLayout';
import Sponser from '../Sponser';

class Policy extends Component {
   
    render() {
  return <SmallLayout account={this.props.account}>
            <div className='text-sm'>    
            PART 1    GENERAL PROHIBITIONS {this.props.sponser}
            <br /><br />
            1.1 You may not post or sell any item that is restricted or prohibited by a federal, state or local law in any country or jurisdiction. Please be aware that the www.paysell.io website (hereinafter the “Paysell.io Website”, which includes and is not limited to its sub-domain wholesale.paysell.io (hereinafter “Wholesale Marketplace”)) and  function as a global marketplace; thus the selling or posting of items may be prohibited because of laws outside of the jurisdiction where you reside. Below, we have listed some categories of prohibited or restricted items. HOWEVER, THIS LIST IS NOT INTENDED TO BE EXHAUSTIVE; YOU, AS THE SELLER, ARE RESPONSIBLE FOR ENSURING THAT YOU ARE NOT POSTING AN ITEM THAT IS PROHIBITED BY LAW IN ANY JURISDICTION. Unless otherwise indicated and annotated accordingly below, the list of prohibited or restricted items as listed shall be applicable to the Paysell.io Website..  
            For the purposes of this Product Listing Policy, transactions which are supported by the Paysell.io Transaction Services Agreement shall be “Relevant Online Transactions”.<br /><br />
            1.2 Paysell.io has chosen to also prohibit the posting of items which may not be restricted or prohibited by law but are nonetheless controversial including:<br /><br />
            (a) Items that encourage illegal activities (e.g. lock pick tools, synthetic urine for cheating drug tests);<br />
            (b) Items that are racially, religiously or ethnically derogatory, or that promote hatred, violence, racial or religious intolerance;<br />
            (c) Giveaways, lotteries, raffles, or contests;<br />
            (d) Stocks, bonds, investment interests, and other securities;<br />
            (e) Pornographic materials or items that are sexual in nature;<br />
            (f) Items that do not offer a physical product or service for sale, such as digital currencies and advertisements solely for the purpose of collecting user information.<br /><br />
            1.3 Paysell.io, in its sole and exclusive discretion, reserves the right to impose additional restrictions and prohibitions.<br /><br />
            1.4 In the event of inconsistency, ambiguity or conflict of the contents of this policy with any other terms of the paysell.io platform, or between the English and other language versions of this policy, the English version and the decision of Paysell.io exercised in its absolute discretion shall always prevail.
            <br /><br />
            PART 2  PROHIBITED AND CONTROLLED ITEMS
<br /><br />
1. ILLICIT DRUGS, PRECURSORS AND DRUG PARAPHERNALIA
1.1 Paysell.io expressly forbids any and all listing or sale of narcotics, tranquilizers, psychotropic drugs, natural drugs, synthetic drugs, steroids and other controlled substances (including all drugs listed in Schedules I, II, III, IV or V of the Uniform Controlled Substances Act, 21 U.S.C. 801 et seq.). Such activity can result in your account being delisted.
1.2 The listing or sale of all drug precursor chemicals (such as those listed in the Convention on Psychotropic Substances of 1971) is strictly prohibited.
1.3 Drug paraphernalia, including all items that are primarily intended or designed for use in manufacturing, concealing, or using a controlled substance, are strictly forbidden on the Site. Such items include, but are not limited to those items used for the ingestion of illicit substances, including pipes such as water pipes, carburetor pipes, chamber pipes, ice pipes, bongs etc.
1.4 The listing or sale of packaging materials which may be utilized to contain controlled substances, materials conducive to smuggling, storing, trafficking, transporting and manufacturing illicit drugs (e.g. marijuana grow lights), publications and other media providing information related to the production of illicit drugs.
<br /><br />
2. FLAMMABLE, EXPLOSIVE AND HAZARDOUS CHEMICALS
2.1 The posting of explosives and related ignition and detonation equipment is strictly prohibited. Such activity can result in your account being delisted.
2.2 Radioactive substances, toxic and poisonous chemicals are forbidden on the Paysell.io Website and .
2.3 The posting, offering for sale, or offering for purchase of hazardous or dangerous materials (such as the categories of dangerous goods as defined under the International Maritime Dangerous Goods Code) are forbidden on the Paysell.io Website .
2.4 Ozone depleting substances are not permitted to be listed.
2.5 The posting, offering for sale, or offering for purchase of any products containing harmful substances (e.g. items containing asbestos) are forbidden on the Paysell.io Website and the .
2.6 Listing of fireworks, firecrackers and associated products are forbidden on both the Wholesaler Marketplace and AliExpress platforms for Relevant Online Transactions. Where the seller is a properly licensed seller of these products in mainland China, and exception can be made where the sale will not amount to a Relevant Online Transaction.
<br /><br />
3. FIREARMS AND AMMUNITIONS
3.1 Any service, instruction, process, or aid for producing any biological, chemical, or nuclear weapons, or other Weapons of Mass Destruction (WMD) or known associated agents is strictly prohibited by international law and accordingly prohibited on the Site. Any violation of this policy will result in the notification of government authorities by Paysell.io and your account being delisted.
3.2 The posting of, offering for sale, or offering for purchase of any arms, munitions, military ordnance, weapons (including explosive weapons), and/or any related parts and components (whether integral or otherwise) is strictly prohibited. Such activity can result in your account being delisted.
3.3 Paysell.io does not permit the posting, offering for sale, or offering of purchase of replica, "look-alike” or imitation firearms, and/or any related parts and components (whether integral or otherwise). This prohibition covers such products as air guns, BB guns, paintball guns, harpoons, spear guns and other weapons that may discharge a projectile containing any gas, chemical, or explosive substance.
<br /><br />
            </div>
            <div className='pt-4'>
            <Sponser 
                        account={this.props.account} 
                        sponser={this.props.sponser}
                        paySponser={this.props.paySponser}
                        transfer={this.props.transfer}
                        pastPayments={this.props.pastPayments} 
                    />
            </div>


            </SmallLayout>
}
}
 export default Policy;
