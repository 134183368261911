import React, {useState} from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { responsive } from './carousel';

import ListingAttributesModal from '../shopping/ListingAttributesModal';
import SimilarProduct from '../../shop/SimilarProduct';

export default function SnackFoods({products, category, title, ...props}) {
    const [product, setProduct] = useState();

    const displayProducts = products.filter(rw => rw.category === 'Snack Foods').sort((a, b) => a.id > b.id ? 1:1).map((product, key) => {
        return (   
            <div className='p-2'>
                <div key={product.id}>
                    <SimilarProduct
                    id={product.id} 
                    title={product.title} 
                    description={product.description}
                    category={product.category} 
                    image={product.image} price={product.price}
                    product={product}
                    addToCart={props.addToCart}
                    />
                </div>
            </div>
                
            )
        })
        
        return (
                <>  
                    <div className='font-bold flex text-2xl pb-2 border-b-2'>
                        {title}
                    </div>
                    <div className="grid grid-flow-row-dense">
                        <Carousel responsive={responsive}> 
                            {displayProducts}
                        </Carousel> 
                    </div>
                    
                    {product && (
                        <Modal
                            show={product}
                            size="lg"
                            onHide={() => setProduct(false)}
                            centered
                        >
                            <Modal.Body>
                                <Row>
                                    <Col>
                                    <ListingAttributesModal listing={products} product={product.id} />
                                    </Col>
                                    <Col>
                                    <h1>{product.name}</h1>
                                    <div class="capitalize text-sm  text-gray-500  font-serif">
                                        {product.category}
                                    </div>
                                    </Col>
                                </Row>
                                </Modal.Body>
                        </Modal>
                        )}
                </>
            )   
}

