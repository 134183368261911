import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'semantic-ui-css/semantic.min.css';
import { PersistGate } from 'redux-persist/integration/react'
import firebaseConfig from "./firebase.config"
import App from './App';
import './main.css';
import { Provider } from "react-redux";
import {store, persistor } from "./redux/store"



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store} firebaseConfig={firebaseConfig}>
      <PersistGate loading={"loading"} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
