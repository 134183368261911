import React from 'react';
import Layout from '../Layout';
import ProductListings from '../feeds/shopping/ProductListings';
// import Categories from '../../Categories';
import ProductListingsSorted from '../feeds/shopping/ProductListingsSorted';
import NewArrivals from '../feeds/shopping/NewArrivals';
import TopRanked from '../feeds/shopping/TopRanked';
import Agrofoods from '../feeds/shopping/AgroFoods';
import Categories2 from '../../Categories2';
import ApparelTextiles from '../feeds/shopping/ApparelTextiles';
import MachineryTools from '../feeds/shopping/MachineryTools';
import AutoTransportation from '../feeds/shopping/AutoTransportation';
// import { Image } from 'react-bootstrap';

export default function Products({account,businessRegistration, daiBalance, dict, cartItems, categories, ...props}) {
        return (
        <div>
            <Layout account={account}
            businessRegistration={ businessRegistration} daiBalance={daiBalance}  dict={dict} cartItems={cartItems} sponser={props.sponser}>
                        <div className='p-3'>
                            <Categories2 categories={categories} />      
                            <div className='hidden md:flex'>
                                <div className='rounded-lg bg-white p-10 w-1/3 border m-2 border-gray-200 hover:bg-gray-50'>
                                    <NewArrivals  products={props.products} />  
                                </div>
                                <div className='rounded-lg bg-white p-10 w-1/3 border m-2 border-gray-200 hover:bg-gray-50'>
                                    <TopRanked products={props.products} /> 
                                </div>
                                <div className='rounded-lg bg-white p-10 w-1/3 border m-2 border-gray-200 hover:bg-gray-50'>
                                    <Agrofoods  products={props.products} /> 
                                </div>
                            </div>     

                             <div className='hidden md:flex'>
                                <div className='rounded-lg w-1/3 bg-white p-10 border m-2 border-gray-200 hover:bg-gray-50'>
                                    <AutoTransportation products={props.products} /> 
                                </div>
                                <div className='rounded-lg w-1/3 bg-white p-10 border m-2 border-gray-200 hover:bg-gray-50'>
                                    <ApparelTextiles products={props.products} />  
                                </div>
                                <div className='rounded-lg w-1/3 bg-white p-10 border m-2 border-gray-200 hover:bg-gray-50'>
                                    <MachineryTools products={props.products} /> 
                                </div>
                            </div>   
                            
                            <div className='p-2  text-lg lg:text-2xl  text-gray-400 font-light'>Latest Product</div>
                            <ProductListings products={props.products} addToCart={props.addToCart} /> 
                        
                        <div className="flex">
                            <div className='flex-1'>
                            <div className='p-2 text-lg lg:text-2xl lg:p-2 text-gray-400 font-light'>Sponsered Product</div>
                                <ProductListingsSorted products={props.products} addToCart={props.addToCart} />
                            </div>
                        </div>
                    </div>
            </Layout>
        </div> )
    }