import { useState } from 'react';

function Faqs({title, description}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="p-2">
        <div className=' bg-white cursor-pointer p-2 rounded-sm'>
            <div className="text-gray text-left font-normal py-2 text-xl rounded" onClick={handleToggle}>
            {title}
            </div>
        </div>
        <div className={`transition-all duration-500 text-left p-2 ${isOpen ? 'block' : 'hidden'}`}>
            {description}
      </div>
    </div>
  );
}

export default Faqs;
