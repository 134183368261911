import React from 'react';
import Nav from 'react-bootstrap/Nav'

function ProductDescription({products, item}) {
        return (
            <div className='pt-5'>
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link active>Description</Nav.Link>
                    </Nav.Item>
                </Nav>
                <div className='p-4 bg-white'>
                    {products.filter(rw=> rw.id === item).map(product => {
                        return(
                                product.description
                            )
                        }
                    )}
                </div>
            </div>

       
    )
}

export default ProductDescription
