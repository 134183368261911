import React from 'react';
import Header2 from './Header2';
import Footer from './Footer';

export default function SmallLayout({account, ...props}) {
  return (
    <div className="p-4 m-auto text-justify w-full md:w-2/4">
        <Header2 account={account} />
       {props.children}
       <div className="pt-16" >
       <Footer/></div>
    </div>
  )
}
