import React, {useState} from 'react';
import Footer from './shop/Footer';
import Header from './shop/Header';
import Modal from 'react-bootstrap/Modal';
import AddProduct from './feeds/seller/AddProduct';

export default function Layout({dict, account, 
  businessRegistration, daiBalance, ...props}){
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
        return (
          <>
          <Header account={account} daiBalance={daiBalance} 
            businessRegistration={businessRegistration} sponser={props.sponser} noSponser={props.noSponser}  cartItems={props.cartItems} handleShow={handleShow} />
          
          <div className='md:w-5/6 md:mx-auto'> 
            <div className='pb-20'>
              {props.children}
            </div>
            <Footer businessRegistration={businessRegistration} account={account} sponser={props.sponser} noSponser={props.noSponser} />
            <Modal
                show={show}
                size="lg"
                onHide={() => setShow(false)}
                centered
            >
                <Modal.Body>
                    < AddProduct account={account} dict={dict} />
                </Modal.Body>
            </Modal>
          </div>
          </>
        )
    }

