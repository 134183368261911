import { bottomHeaderItems } from "../../constants"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useEffect, useState, useRef } from "react";
import SideNavContent from "./SideNavContent";
import { useSelector } from 'react-redux'; 


function HeaderBottom() {
    const ref = useRef();
    const [sidebar, setSideBar] = useState(false)
    const userInfo = useSelector((state)=> state.paysell.userInfo);

    useEffect(() => {
        document.body.addEventListener("click", (e) => {
            if (e.target.contains(ref.current)){
                setSideBar(false);
            }
        })
    }, [ref, sidebar])

  return (
    <div className="w-full px-4 h-12 flex items-center bg-paysell_light text-white">
        <ul className="flex items-center gap-2 text-md tracking-wide">
            <li onClick={()=>setSideBar(true)} className="headerHover flex items-center gap-1"><MenuIcon />All</li>
            {
                bottomHeaderItems.map((items)=>(
                    <li key={items._id} className="headerHover">{items.title}</li>
                ))
            }
        </ul>
        {sidebar && (
            <div className="w-full h-screen text-black fixed top-0 left-0 bg-paysell_dark bg-opacity-50">
                <div className="w-full h-full relative">
                    <div className=" w-2/12 h-full bg-white border border-black">
                        <div className="w-full bg-paysell_light text-white py-2 px-6 flex items-center gap-4">
                            <AccountCircleIcon />
                            {userInfo ? (
                                <h3 className="font-titleFont font-bold text-lg tracking-wide">
                                {userInfo.userName}
                            </h3>
                            ) : (
                                <h3 className="font-titleFont font-bold text-lg tracking-wide">
                                Hello, Guest
                            </h3>
                            )}
                        </div>
                            <SideNavContent 
                                title="Fresh Produce"
                                one="Paysell Oranges"
                                two="Tomatoes"
                                three="Fruits"
                            />
                            <SideNavContent 
                                title="Fresh Produce"
                                one="Paysell Oranges"
                                two="Tomatoes"
                                three="Fruits"
                            />
                            <SideNavContent 
                                title="Fresh Produce"
                                one="Paysell Oranges"
                                two="Tomatoes"
                                three="Fruits"
                            />
                            <SideNavContent 
                                title="Fresh Produce"
                                one="Paysell Oranges"
                                two="Tomatoes"
                                three="Fruits"
                            />
                        </div>
                        <span onClick={()=>setSideBar(false)} className="cursor-pointer absolute top-0 left-96 w-10 h-10 text-black flex items-center justify-center border bg-gray-200 hover:bg-red-500 hover:text-white duration-300"><CloseIcon /></span>
                    </div>
                </div>
        )}
    </div>
  )
}

export default HeaderBottom