import React from 'react';
import Layout from '../Layout'

export default function Enquiries({account,ListingEnquries, sponser, daiBalance, businessRegistration, categories,
    second_subcategories, dict, cartItems, products, ...props}) {
      
  return (
      <Layout account={account} sponser={sponser} daiBalance={daiBalance} businessRegistration={businessRegistration} categories={categories} 
          dict={dict} cartItems={cartItems}>
        <div className='w-full p-3'> 
            <div className='rounded-lg flex bg-white p-10 border m-2 border-gray-200 hover:bg-gray-50'>
              <div className='w-full sm:w-1/5 '>All Enquiries</div>    
              <div className='flex w-full'>
              <table class="table-auto hover:table-fixed">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th className='text-center'>Quantity</th>
                  </tr>
                </thead>
                { ListingEnquries.sort((a, b) => a.id > b.id ? 1:1).map((e, key) => {
                    return ( 
                        <tr key={e.id}>
                          <td className='p-2'>{e.title}</td>
                          <td className='p-2 text-center'>{e.quantity}</td>
                        </tr>
                        )  
                      })
                    }
                  </table>
              </div> 
            </div>
          </div>

      </Layout>
  )
}
