import React, { Component } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';

class BuyNow extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
        
    }
    onSubmit= async (event) => {
        event.preventDefault()
        this.setState({ loading: true, errorMessage: '' });
        try {
        const account = this.account.value

        let amount = this.amount.value
        this.props.transfer(account, window.web3.utils.toWei(amount, 'Ether'))
        } catch (err) {
        this.setState({ errorMessage: err.message });
    }
    
    }
    render() {
        return (
        <div>
            <Form onSubmit={this.onSubmit} error={!!this.state.errorMessage}>
                <input 
                    id="account"
                    type="hidden"
                    value={this.props.owner}
                    ref={(input) => { this.account = input }}
                /> 
                <input 
                    id="amount"
                    type="hidden"
                    value={this.props.amount}
                    ref={(input) => { this.amount = input }}
                /> 
                <Message error header="Oops!"  content='Please contact the administrator. Thank you'/>
                <Button loading={this.state.loading} color='yellow' content='Yellow'  style={{width: "150px"}} size="small">Buy Now</Button>
            </Form>
        </div>
        )
    }
}
export default BuyNow;