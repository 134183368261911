import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { paysell } from '../../assets';


class Header2 extends Component {
    render() {
        return (
            <nav className="navbar navbar-dark  z-50 bg-light text-gray flex-md-nowrap p-0">
                <div className="flex pb-4">
                    <div className="flex pr-2">
                    <Link to="/"> 
                            <img src={paysell} alt='Paysell' width={100} /> 
                        </Link>
                    </div>
                </div>
            </nav>
        )
        
    }
}
export default Header2;