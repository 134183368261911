import React, {useState} from 'react';
import Layout from '../../Layout';
import { Image, Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import SimiliarListings from './SimiliarListings'
import SellerInfo from '../seller/SellerInfo';
import BreadCrumbs from '../../shop/BreadCrumbs';
import ProductDescription from './ProductDescription';
import BuyNow from '../../shop/BuyNow';
// import AddToCartButton from './AddToCartButton';
import LeadTime from '../seller/LeadTime';
import Shipping from './Shipping';
import AddListingPics from '../seller/AddListingPics';
import { Button } from "reactstrap";
import ContactSupplier from "../seller/ContactSupplier";
import Verified from './detailed/Verified';
import Cost from './detailed/Cost';
import StarRating from './detailed/StarRating';
import { useDispatch } from "react-redux";
import { decrementQuantity, incrementQuantity, addToCart } from "../../../redux/slice";
// import PayRoyalty from '../../shop/PayRoyalty';

export default function ProductDetail({account, businessRegistration, profile, sponser, daiBalance, dict, cartItems, products, images, match, ...props}) {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [contact, setContact] = useState(false);
    const handleContact = () => setContact(true);
    // eslint-disable-next-line 
    const displayProduct = products.filter(rw => rw.id == match.params.id).map(product => {
        return (
            <div className='p-4 bg-white'>
                <BreadCrumbs category={product.category} />
                <Row className='bg-white pb-5'> 
                    <Col lg={5} className="pl-4">
                        <center>
                            <Image src={`${product.image}`} className="max-h-96" rounded />
                        </center>
                        {account === product.owner ?       
                        <Button variant="primary" onClick={handleShow} className='mt-2'>
                            [+ Add more Pictures]
                        </Button>
                        :<></>
                        }
                    </Col>
                    <Col lg={4} style={{paddingTop: "30px"}}>
                        <div style={{fontSize: "16px", fontWeight:"800"}}>
                                {product.title}
                            </div>
                            <div style={{fontSize: "12px", paddingBottom: "20px", textAlign: "left"}}>
                                Category: <Link to={`/${product.category}`} style={{color:"#000"}}>{product.category}</Link>
                            </div>
                            <hr />
                            <div style={{fontSize: "25px", fontWeight:"800", paddingTop: "20px", paddingBottom: "30px"}}>
                                <div className='flex'>
                                    <div className='flex-1'>
                                        <LeadTime moq={product.moq} measurementType={product.measurementType} />
                                    </div>
                                </div>
                                <div>
                                    <Cost quantity={product.price} />
                                    <div className='flex'>
                                        { product.quantity > 0 ?(
                                        <div>
                                            <div className=' bg-gray-100 flex justify-center -items-center gap-1 w-52 py-1 text-center drop-shadow-lg rounded-md'>
                                                <p>Qty:</p>
                                                <p onClick={()=>dispatch(decrementQuantity(product.id))} className=' cursor-pointer bg-gray-200 px-1 rounded-md hover:bg-gray-400 duration-300'>-</p>
                                                <p className='p-2 text-white'>{product.quantity}</p>
                                                <p onClick={()=>dispatch(incrementQuantity(product.id))} className=' cursor-pointer bg-gray-200 px-1 rounded-md hover:bg-gray-400 duration-300'>+</p>
                                            </div>
                                        </div> ) : (<></>)}

                                        <div className='p-1'>
                                            <button onClick={()=>dispatch(addToCart({
                                                id:product.id,
                                                title:product.title,
                                                description:product.description,
                                                image:product.image,
                                                price:product.price,
                                                category:product.category,
                                                quantity:1
                                            }))} className='bg-yellow-400 text-white p-2 text-sm rounded-sm'>Add To Cart</button>
                                        </div>
                                        <div className='p-1'>
                                            <a href="/cart" className='bg-yellow-400 font-semibold text-white p-2 text-sm rounded-sm'>Review Cart</a>
                                        </div>
                                    </div>
                                    
                                    {profile.filter(rw => rw.owner === product.owner).map(acct => {
                                        return (
                                            <>{acct.verify === 'Verified'  ?
                                            <> 
                                                <div className='hidden sm:flex p-1'>
                                                    <BuyNow transfer={props.transfer} daiBalance={daiBalance}  payAccount={props.payAccount} owner={product.owner} amount={product.price}/>
                                                </div>
                                                <Verified />
                                                <StarRating  />
                                            </>
                                        :
                                        <div className='text-sm'></div>
                                        
                                        }</>
                                        )
                                    })}
                                        
                                        {/* <PayRoyalty
                                            transfer={props.transfer} payRoyalty={props.payRoyalty} amount={product.price}  owner={product.owner}
                                        /> */}
                                        {/* <AddToCartButton addToCart={props.addToCart} product={product} /> */}
                                    </div>
                            </div>
                            <hr />
                            <Shipping availability={product.availability} />
                    </Col>
                    <Col>
                        <SellerInfo account={account} seller={product.owner} profile={profile} businessRegistration={businessRegistration} handleContact={handleContact} />
                    </Col>
                </Row>
                <Row>
                    <Col><h1 style={{fontSize: "20px", fontWeight:"800" ,paddingBottom: "10px"}}>Similar items</h1></Col>
                </Row>
                
                <Row className="flex flex-col bg-white p-2 border border-gray-200">
                    <Col>
                        <div className='text-xs text-gray-300'>
                            Find even more on PaySell by browsing through similar ads below. 
                        </div>
                        <SimiliarListings products={products} category={product.category} />
                    </Col>
                </Row>
                
                <Row>
                    <Col className='p-0 pb-40' style={{minHeight: "250px"}}>
                        <ProductDescription products={products} item={product.id}  />
                        {images.filter(i => i.rootid === product.id).map(image => {
                            return (  
                            <div className='pl-3 m-auto' style={{maxWidth:"750px"}}>
                                <center>
                                    <Image src={`${image.img}`} className="fluid" />
                                </center>    
                            </div>
                            )  })}
                    </Col>
                </Row>
               
                <Modal
                show={show}
                size="sm"
                onHide={() => setShow(false)}
                centered
            >
                <Modal.Body>
                    <AddListingPics rootid={product.id} />
                </Modal.Body>
            </Modal>
            <Modal
                show={contact}
                size="lg"
                onHide={() => setContact(false)}
            >
                <Modal.Body>
                    <ContactSupplier account={account} image={product.image} title={product.title} rootid={product.id} owner={product.owner} />
                </Modal.Body>
            </Modal>
        </div>
        )})
    return (
        <Layout account={account}  sponser={sponser}
        businessRegistration={businessRegistration} daiBalance={daiBalance}  dict={dict} cartItems={cartItems}>
            {displayProduct}
        </Layout>
    )
}
