import React from 'react';
import {Card, CardGroup} from 'react-bootstrap/'

export default function LeadTime({moq, measurementType}) {
  return <div className='flex pb-3'>
        <p className='p-2 text-sm font-thin'>Minimum Order Quantity (moq)</p>
        <CardGroup className="sm:w-96 text-lg">     
            <Card className='p-2 text-center'>
                <Card.Text>
                    Quantity
                </Card.Text>
            </Card>
            <Card className='p-2 text-center'>
                <Card.Text>
                    {moq} ({measurementType}) 
                </Card.Text>
            </Card>
        </CardGroup>
  </div>;
}
