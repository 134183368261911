import React from 'react'
import ShopListings from './components/feeds/shopping/ShopListings'
import CategoryLayout from './components/shop/CategoryLayout'

export default function Category({account, sponser, daiBalance, businessRegistration, categories,
    second_subcategories, dict, cartItems, products, match, ...props}) {
        
    return (
        <CategoryLayout account={account} sponser={sponser} daiBalance={daiBalance} businessRegistration={businessRegistration} categories={categories} category={match.params.category} 
         dict={dict} cartItems={cartItems}>
            <ShopListings account={account} addToCart={props.addToCart} products={products}  category={match.params.category} 
                second_subcategories={second_subcategories}/>
        </CategoryLayout>
    )
}
