import React from 'react'
import { Image } from 'react-bootstrap';

export default function Verified() {
  return (
    <div> <p className='text-sm pt-10'>
    <Image className='float-left' src="/img/verified.png" style={{width:"25px"}} />
    <p>Verified Supplier</p>
</p></div>
  )
}
