import React from 'react';

export default function Shipping({availability}) {
  return (<>
    <div className='flex text-sm pt-2'>
      <div className='p-2'>
          <div className='bg-yellow-200 p-1 rounded'>{availability}</div>
        </div> 
    </div>
    {/* <div className='flex text-sm'>
    <div className='p-2 font-bold'>Shipping costs: </div>
        <div className='p-2'>Contact seller for details.</div>
    </div> */}
        </>
  );
}
