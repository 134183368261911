import React, { Component } from 'react';
import { Button, Form, Message } from 'semantic-ui-react'

class Sponser extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          errorMessage: '',
        };
      }
    onSubmit= async (event) => {
        event.preventDefault()
        this.setState({ loading: true, errorMessage: '' });
        try {
        const member = this.sponser.value
        let amount = window.web3.utils.toWei('5', 'Ether')
        this.props.paySponser(member, amount)
        } catch (err) {
        this.setState({ errorMessage: err.message });
    }
    
    }
    render() {
      const sponserReceipt = this.props.pastPayments.find(rw => rw.returnValues.to === this.props.sponser);
    
        return (
         <div>
          <Form onSubmit={this.onSubmit} error={!!this.state.errorMessage}>
            <input 
              id="sponser"
              type="hidden"
              value={this.props.sponser}
              ref={(input) => { this.sponser = input }}
            /> 
            <Message error header="Oops!"  content='Please contact the administrator. Thank you'/>
            {sponserReceipt ?
            <></>
            : <><Button loading={this.state.loading} color='yellow' content='Yellow'>Confirm Agreement</Button></>
            }
          </Form> 
          </div>
        )
    }
}
export default Sponser;
