import React, { useState , useEffect } from 'react';
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form'
import { Button,  Input} from "reactstrap";
import SmallLayout from '../../shop/SmallLayout';

const EditProduct = (props) => {
    let history = useHistory();
    const { id } = useParams();

    const [title, setTitle] = useState(null)
    const [description, setDescription] = useState(null)
    const [price, setPrice] = useState(null)
    const [category, setCategory] = useState(null)
    const [moq, setMoq] = useState(null)
    const [measurementType, setMeasurementType] = useState(null)
    const [owner, setOwner] = useState(null)
    const [status, setStatus] = useState(null)

    let getData = async () => {
        const { data } = await axios.get(`https://paysell.app/ads/update/listing/${id}/`)
        
        setTitle(data.title);
        setDescription(data.description);
        setPrice(data.price);
        setCategory(data.category);
        setMoq(data.moq);
        setMeasurementType(data.measurementType);
        setOwner(data.owner); 
        setStatus(data.status);
    }
        
    useEffect(() => {
        getData()
    }, // eslint-disable-next-line
    [])

    const updateListing = async () => {
        
        let formField = new FormData()
        
        formField.append('title',title)
        formField.append('description',description)
        formField.append('category',category)
        formField.append('moq',moq)
        formField.append('measurementType',measurementType)
        formField.append('owner',owner)
        formField.append('status',status)

        await axios({
            method: 'PUT',
            url: `https://paysell.app/ads/update/listing/${id}/`,
            data: formField
        }).then(response => {
            console.log(response.data);
            history.push("/success");
        })

    }
    
    return ( 
        <SmallLayout account={props.account}>
            <div className='font-bold text-xl sm:text-4xl pt-8 pb-4'>Edit list</div>
            
                    <p className='p-1'>
                    <Input
                        id="title"
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        placeholder='Product Title'
                        required
                    />
                    </p>
                    <p className='p-1'>
                    <Form.Control 
                        as="textarea" 
                        rows={5}
                        id="description"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        required
                    />
                    </p>
                    <p className='p-1'>
                <Input
                    id="price"
                    type="number"
                    onChange={(e) => setPrice(e.target.value)}
                    value={price}
                    placeholder='Selling Price'
                    required
                />
                </p>
                <p className='p-1'>
                    <select id="category" className='form-control' onChange={(e) => setCategory(e.target.value)}
                        value={category} required>
                            <option value="">Select Category</option>
                        {props.categories.map(rw => (
                            <option value={rw.name} key={rw.id}>{rw.name}</option>
                        ))}
                    </select>
                </p>
                
                <p className='p-1'>
                    <Input
                        id="moq"
                        type="number"
                        onChange={(e) => setMoq(e.target.value)}
                        value={moq}
                        placeholder='Minimum Order Quantity'
                        required
                    />
                </p>
                <p className='p-1'>
                    <select id="measurementType" className='form-control' onChange={(e) => setMeasurementType(e.target.value)}
                        value={measurementType} required>
                            <option value="">Select Measurement Type</option>
                        {props.dict.filter(rw => rw.category === 'measurementType').map(rw => (
                            <option value={rw.name} key={rw.id}>{rw.name}</option> 
                        ))}
                    </select>
                </p>
                <p className='p-1'>
                    <Input
                        id="owner"
                        type="hidden"
                        onChange={(e) => setOwner(e.target.value)}
                        value={owner}
                    />
                </p>
                <p className='p-1'>
                    <Input
                        id="status"
                        type="hidden"
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}
                    />
                </p>
                <br />
                <div className='text-center'>
                <Button variant='warning' onClick={updateListing}>Edit Listing</Button>
                </div>
            
        </SmallLayout>
    )
}

 export default EditProduct;
