import React from 'react';
import Identicon from 'identicon.js';

export default function UserIcon(props) {
  return <div className='p1'>
    <img
        className='ml-2'
        width='30'
        height='30'
        src={`data:image/png;base64,${new Identicon(props.account, 30).toString()}`}
        alt=""
    />
  </div>;
}
