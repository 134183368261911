import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import { CheckCircleIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { decrementQuantity, incrementQuantity, deleteItem, resetCart } from '../redux/slice';

export default function Cart({account, businessRegistration, sponser, noSponser }) {
    const dispatch = useDispatch()
    const products = useSelector((state) => state.paysell.products);
    const userInfo = useSelector((state) => state.paysell.setUserInfo)
    const [totalPrice, setTotalPrice] = useState("");
    // const [payNow, setPayNow] = useState(false);
    // const [payErr, setPayErr] = useState("")

    useEffect(()=>{
        let Total = 0
        products.map((item)=>{
            Total += item.price * item.quantity;
            return setTotalPrice(Total.toFixed(2))
        })
    }, [products]);

    const handleCheckout = () => {
        if (userInfo) {
            // setPayNow(true);
        } else {
            // setPayErr("Please sign in to check out");
        }
    }

  return (
    <Layout  account={account}  
    businessRegistration={businessRegistration} sponser={sponser} noSponser={noSponser}>
        <div className=' w-full, bg-gray-100'>
            { products.length > 0 ? (
                <div className='p-3 mx-auto h-auto grid grid-cols-5 gap-8'>
                <div className='w-full h-full bg-white px-4 col-span-4'>
                    <div className="font-titleFont flex items-center justify-between 
                    border-b-[1px]  py-3 border-b-gray-400">
                        <h2 className='text-3xl font-medium'>Shopping Cart</h2>
                        <h4 className='text-xl font-normal'>Subtitle</h4>
                    </div>
                    <hr />
                    <div>
                        {
                            products.map((item)=>(<>
                                <div key={item.id} className='w-full border-b-[1px] border-b-gray-300 p-4 flex items-center gap-6'>
                                <div className=' w-full flex items-center gap-6'>
                                    <div className='w-1/5'>
                                        <img className='w-full h-44 object-contain' src={item.image} alt="Img" />
                                    </div>
                                    <div className='w-4/5'>
                                        <h2 className=' font-semibold text-lg'>{item.title}</h2>
                                        <p className='pr-10 text-sm'>{item.description.substring(0,200)}</p>
                                        <p className='text-base'>Unit Price <span className='font-semibold'>${item.price}</span></p>
                                        <div className=' bg-gray-100 flex justify-center -items-center gap-1 w-24 py-1 text-center drop-shadow-lg rounded-md'>
                                            <p>Qty:</p>
                                            <p onClick={()=>dispatch(decrementQuantity(item.id))} className=' cursor-pointer bg-gray-200 px-1 rounded-md hover:bg-gray-400 duration-300'>-</p>
                                            <p>{item.quantity}</p>
                                            <p onClick={()=>dispatch(incrementQuantity(item.id))} className=' cursor-pointer bg-gray-200 px-1 rounded-md hover:bg-gray-400 duration-300'>+</p>
                                        </div>
                                        <button onClick={()=>dispatch(deleteItem(item.id))} className=' bg-red-500 w-36 py-1 rounded-lg text-white mt-2 hover:bg-red-700 active:bg-red-900 duration-300'> Delete item</button>
                                    </div>
                                    <div>
                                        <p className='text-lg font-titleFont font-bold'>
                                            ${item.price * item.quantity}
                                        </p>
                                    </div>
                                    </div>
                                </div>
                                <hr />
                                </>))
                        }
                    </div>
                    <div className='w-full py-2'>
                        <button onClick={()=>dispatch(resetCart())} className='px-10 py-2 bg-red-500 hover:bg-red-600 active:bg-red-500 text-white rounded-lg font-semibold text-lg tracking-wide'>Clear Cart</button>
                    </div>
                </div>
                <div className='w-full h-52 bg-white col-span-1 flex flex-col  items-center p-4'>
                    <div className='flex'>
                        <div className='m-1'>
                            <span className='bg-white w-5 text-green-500 rounded-full'>
                                <CheckCircleIcon className='w-5' />
                            </span>
                        </div>
                        <div className='p-1'>
                            Your order qualifies for free shipping
                        </div>
                    </div>  
                    <div className=''>
                        <p className='font-semibold px-10 py-1 flex items-center '>Total: <span className='text-lg font-bold pl-2'>${totalPrice}</span>
                        </p>
                    </div>
                    <button onClick={handleCheckout} className='w-full font-titleFont font-medium text-base bg-gradient-t-tr  bg-yellow-300 from-yellow-400 to-yellow-200 border hover:from-yellow-300 hover:to-yellow-200  border-yellow-400 hover:border-yellow-700 active:bg-gradient-to-bl active:from-yellow-400 active:to-yellow-500 duration-200 py-1.5 rounded-md'>Proceed to Pay</button>
                </div>
            </div>
            ) : (
                <div className=' p-4 w-full h-52'>
                    <div className='w-96 p-4 bg-white text-center rounded-md shadow-lg mx-auto'>
                        <span>Your cart is empty.</span>
                        <p className='pt-2 items-center text-center'>
                        <a href="/store" className="bg-yellow-400 p-2 rounded-md">Continue Shopping</a>

                        </p>
                    </div>
                </div>
            )}
        </div>
    </Layout>
  )
}
