import React, {useState} from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Modal from 'react-bootstrap/Modal'

import ListingAttributesModal from './ListingAttributesModal';
import Product from '../../shop/Product';
import CategoryLayout from '../../shop/CategoryLayout';

export default function Shop({account, daiBalance,
    businessRegistration, categories, cartItems, products, match, ...props}) {
    const [pageNumber, setPageNumber ] = useState(0)
    const [product, setProduct] = useState();

    const productsPerPage = 20
    const pagesVisited = pageNumber * productsPerPage
    const displayProducts = products.filter(rw => rw.owner ===  match.params.shop).slice(pagesVisited, pagesVisited + productsPerPage).sort((a, b) => a.id > b.id ? 1:1).map((product, key) => {
        return (   
            <div className="p-2">
                <div key={product.id} className="flex flex-col rounded-lg  bg-white p-1 border border-gray-200 hover:bg-gray-50 min-h-full hover:shadow-xl">
                    <Product
                        id={product.id} 
                        title={product.title} 
                        category={product.category} 
                        image={product.image} 
                        price={product.price}
                        measurementType={product.measurementType}
                        moq={product.moq}
                        product={product}
                        addToCart={props.addToCart}
                    />
                </div>
            </div>
                
            )
        })
        const pageCount = Math.ceil(products.length / productsPerPage);
        const changePage = ({selected}) => {
            setPageNumber(selected)
        }
        return (
                <CategoryLayout account={account} daiBalance={daiBalance}
                businessRegistration={businessRegistration} cartItems={cartItems} categories={categories} >
                    <div className="grid grid-flow-row-dense grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        {displayProducts}
                    </div>
                    <div className='pt-32'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={changePage}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            renderOnZeroPageCount={null}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </div>
                    {product && (
                        <Modal
                            show={product}
                            size="lg"
                            onHide={() => setProduct(false)}
                            centered
                        >
                            <Modal.Body>
                                <Row>
                                    <Col>
                                        <ListingAttributesModal listing={products} product={product.id} />
                                    </Col>
                                    <Col>
                                        <h1>{product.name}</h1>
                                        <div class="capitalize text-sm  text-gray-500  font-serif">
                                            {product.category}
                                        </div>
                                    </Col>
                                </Row>
                                </Modal.Body>
                        </Modal>
                        )}
                </CategoryLayout>
            )   
}

