import React, { Component } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form'
import { Button,  Input} from "reactstrap";
import SmallLayout from '../../shop/SmallLayout';

export default class OpenShop extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            rootid: null,
            companyName: null,
            address1: null,
            city: null,
            country: null,
            companyOwner: null,
            mobile: '',
            email: '',
            idCard: null,
            numberOfEmployees: '',
            status: null,
          };
        }

        componentDidMount(){
            this.setState({
                status: "Active" 
            })
        }
        onChange = (e) => {
            let { id, value } = e.target;
            this.setState({ [id]: value })
        }
    
        handleImageChange = (e) => {
            this.setState({
                idCard: e.target.files[0],
                regCertificate: e.target.files[0]
            })
          };

          handleSubmit = (e) => {
            e.preventDefault();
            let form_data = new FormData();
            form_data.append('rootid', this.state.rootid);
            form_data.append('companyName', this.state.companyName);
            form_data.append('address1', this.state.address1);
            form_data.append('city', this.state.city);
            form_data.append('country', this.state.country); 
            form_data.append('mobile', this.state.mobile);
            form_data.append('email', this.state.email);
            form_data.append('numberOfEmployees', this.state.numberOfEmployees);
            form_data.append('status', this.state.status);
            let url = `https://paysell.app/api/business-info/`;
            axios.post(url, form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              }).then(res => {
                console.log(res.data);
                window.location="/business-summary/"
              })
              .catch(err => console.log(err))
            }
        

  render() {
      const {dict} = this.props
    return (
        <SmallLayout account={this.props.account}>
            <div className='w-full pt-10 rounded-lg bg-white sm:p-10 border m-2 border-gray-200 hover:bg-gray-50'>
                <p className='text-4xl p-2'>Seller Center</p>
                <p className='p-3'>
                    <hr />
                </p>
                <p className='p-2'>
                    <Form onSubmit={this.handleSubmit}>
                    <div className='flex pb-10'>
                        <div className='hidden w-full text-right p-2 font-bold'>Account Name</div>
                            <div className=' w-full'>
                                <p className='p-1'>
                                <select className='form-control' id='rootid' onChange={this.onChange} value={this.state.rootid} required>
                                        <option value="">Select Account Name</option>
                                                <option value={this.props.account}>{this.props.account}</option>
                                    </select>
                                </p>
                            </div>
                        </div>
                           
                        <div className='flex'>
                            <div className='hidden w-full text-right p-2 font-bold'>Shop Name</div>
                            <div className=' w-full'>
                                <p className='p-1'>
                                    <Input
                                        id="companyName"
                                        type="text"
                                        onChange={this.onChange}
                                        value={this.state.companyName}
                                        placeholder='Shop Name'
                                        required
                                    />
                                    <p className='text-xs text-gray-600'>Choose a unique name for your online Shop.</p>
                                </p>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='hidden w-1/4 text-right p-2 font-bold'>City</div>
                            <div className='w-full'>
                        <p className='p-1'>
                        <select className='form-control' id='city' onChange={this.onChange} value={this.state.city} required>
                                        <option value="">Choose City</option>
                                        {dict.filter(rw => rw.category === 'city').map(i => {
                                            return( 
                                                <option value={i.name} key={i.id}>{i.name}</option>
                                            )
                                        })}
                                    </select>
                        </p>
                        </div>
                        </div>
                        <div className='flex'>
                            <div className='hidden w-1/4 text-right p-2 font-bold'>Country</div>
                            <div className='w-full'>
                                <p className='p-1'>
                                    <select className='form-control' id='country' onChange={this.onChange} value={this.state.country} required>
                                        <option value="">Choose Country</option>
                                        {dict.filter(rw => rw.category === 'country').map(i => {
                                            return( 
                                                <option value={i.name} key={i.id}>{i.name}</option>
                                            )
                                        })}
                                    </select>
                                </p>
                                </div>
                        </div>

                        <div className='flex'>
                            <div className='hidden w-1/4  text-right p-2 font-bold'>Mobile</div>
                            <div className='w-full'>
                                <p className='p-1'>
                                    <Input
                                        id="mobile"
                                        type="text"
                                        onChange={this.onChange}
                                        value={this.state.mobile}
                                        placeholder='Contact Number'
                                        required
                                    />
                                </p>
                            </div>
                        </div>

                        <div className='flex'>
                            <div className='hidden w-1/4  text-right p-2 font-bold'>Email</div>
                            <div className='w-full'>
                                <p className='p-1'>
                                    <Input
                                        id="email"
                                        type="email"
                                        onChange={this.onChange}
                                        value={this.state.email}
                                        placeholder='Email Address'
                                        required
                                    />
                                </p>
                            </div>
                        </div>

                        
                        <div className='flex'>
                            <div className='hidden w-1/4 sm:w-full text-right p-2 font-bold'>Number or employees</div>
                            <div className='w-full'>
                                <p className='p-1'>
                                <select className='form-control' id='numberOfEmployees' onChange={this.onChange} value={this.state.numberOfEmployees} required>
                                        <option value="">Number of Employees</option>
                                        {dict.filter(rw => rw.category === 'range').map(i => {
                                            return( 
                                                <option value={i.name} key={i.id}>{i.name}</option>
                                            )
                                        })}
                                    </select>
                                </p>
                            </div>
                        </div>
                        <p className='p-1'>
                            <Input
                                id="status"
                                type="hidden"
                                onChange={this.onChange}
                                value={this.state.status}
                            />
                        </p>
                        <p className='pt-10'>
                            <Button variant='warning'>Submit Registration</Button>
                        </p>
                    </Form>
                </p>
            </div>

        </SmallLayout>
    )
  }
}
