import React, {useState}  from 'react';
import { Container } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Header from '../shop/Header';
import BreadCrumbs from './BreadCrumbs';
import Footer from './Footer';
import AddProduct from '../feeds/seller/AddProduct';
import AllCategories from '../../AllCategories';
 
function CategoryLayout({dict, account, sponser, daiBalance, businessRegistration, categories, ...props}){
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
        return (
        <div className='bg-white'>
          <Header account={account} sponser={sponser} daiBalance={daiBalance} businessRegistration={businessRegistration}  cartItems={props.cartItems} handleShow={handleShow} />
            <Container className='pb-20'>
              <BreadCrumbs category={props.category} />
              <div className='flex'>
                <div className="hidden sm:flex"><AllCategories categories={categories} /></div>
                <div className="w-full md:w-3/4">{props.children}</div>
              </div>
            </Container>
          <Footer />
          <Modal
                show={show}
                size="lg"
                onHide={() => setShow(false)}
                centered
            >
                <Modal.Body>
                    <AddProduct account={account} dict={dict} />
                </Modal.Body>
            </Modal>
        </div>  
      )
}

export default CategoryLayout;

