import React, {useState} from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ListingAttributesModal from './ListingAttributesModal';
import Product from '../../shop/Product';

export default function ProductListings({products, ...props}) {
    const [product, setProduct] = useState();

    const displayProducts = products.sort((a, b) => a.id < b.id ? 1:-1).slice(0,12).map((product, key) => {
        return (   
            <div className="p-2 text-left">
                <div key={product.id} className="flex flex-col rounded-lg  bg-white p-1 border border-gray-200 hover:bg-gray-50 min-h-full hover:shadow-xl">
                    <Product
                        id={product.id} 
                        title={product.title} 
                        category={product.category} 
                        image={product.image} price={product.price}
                        moq={product.moq}
                        measurementType={product.measurementType}
                        availability={product.availability}
                        product={product}
                        addToCart={props.addToCart}
                    />
                    {/* <p className='text-right right-2 text-xs italic text-gray-400 lg:pt-5'>
                        <Link to={"#" + product.id}  onClick={() => setProduct(product)}>
                            Report Abuse
                        </Link>
                    </p>      */}
                </div>
            </div>
                
            )
        })
        return (
                <>
                    <div className="grid grid-flow-row-dense grid-cols-2 lg:grid-cols-3 xl:grid-cols-6">
                        {displayProducts}
                    </div>
                    {product && (
                        <Modal
                            show={product}
                            size="lg"
                            onHide={() => setProduct(false)}
                            centered
                        >
                            <Modal.Body>
                                <Row>
                                    <Col>
                                    <ListingAttributesModal listing={products} product={product.id} />
                                    </Col>
                                    <Col>
                                        <h1>{product.name}</h1>
                                        <div class="capitalize text-sm  text-gray-500  font-serif">
                                            {product.category}
                                        </div>
                                    </Col>
                                </Row>
                                </Modal.Body>
                        </Modal>
                        )}
                </>
            )   
}

