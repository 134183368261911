export const allItems = [
    { _id : 100, title: "All Fresh" },
    { _id : 101, title: "Beverages" },
    { _id : 102, title: "Breads & Bakery" },
    { _id : 103, title: "Dairy, Cheese & Eggs" },
    { _id : 104, title: "Frozen Foods" },
    { _id: 105,title:"Meat & Seafood"},
    { _id: 106,title:"Snack Foods"},
    { _id: 107,title:"Pantry Staples"},
    { _id: 108,title:"Produce"},
]

export const bottomHeaderItems = [
    { _id : 101, title: `Today's Deals` },
    { _id : 102, title: "Customer Service" },
    { _id : 103, title: "Gift Cards" },
    { _id : 104, title: "Registry" },
    { _id: 105,  title: "Sell"},
]