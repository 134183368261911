import React, { Component  } from 'react';
import axios from "axios";
import Form from 'react-bootstrap/Form'
import { Button,  Input} from "reactstrap";
import UserIcon from '../UserIcon';
import { Image } from 'react-bootstrap';

export default class ContactSupplier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rootid: null,
            title: null,
            quantity: '',
            description: '',
            client: null,
            owner: null,
            status: null,
          };
        } 

        componentDidMount(){
            this.setState({
                rootid: this.props.rootid,
                title: this.props.title,
                client: this.props.account,
                owner: this.props.owner,
                status: "Active"
            })
        } 
      onChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value })
    }
    handleFormSubmit = async (e) => {
        e.preventDefault();
            await axios.post(`https://paysell.app/ads/contact_supplier/`, this.state).then(() => {
            window.location="/success/"
            });
        }
    
    render() {
    return ( 
        <div>
            <div className='bg-yellow-400 p-1  m-2'>
                <div className='flex'>
                    <div className='m-2'>
                        To :
                    </div>
                    <div>
                    <UserIcon account={this.props.owner} />
                    </div>
                </div>
            </div>
            <div className='flex p-2'>
                <div className='p-1'>
                <Image
                    height={80} width={80}
                    src={this.props.image} alt={this.props.title} rounded />
                </div>
                
                <div className='p-6 text-justify'>
                    {this.props.title}
                </div>
            </div>

            <Form onSubmit={e =>
                this.handleFormSubmit(e)}>
                    <p className='p-1'>
                    <Input
                        name="title"
                        type="hidden"
                        onChange={this.onChange}
                        value={this.state.title}
                        placeholder='Title'
                    />
                    </p>
                    <p className='p-1'>
                        <Input
                            name="quantity"
                            type="number"
                            onChange={this.onChange}
                            value={this.state.quantity}
                            placeholder='Quantity'
                        />
                    </p>
                    <p className='p-1'>
                    <Form.Control 
                        as="textarea" 
                        rows={5}
                        name="description"
                        onChange={this.onChange}
                        value={this.state.description} 
                        placeholder='Enter product description, size, color etc'
                    />
                    </p>
                    
                    <p className='p-1'>
                    <Input
                        name="client"
                        type="hidden"
                        onChange={this.onChange}
                        value={this.state.client}
                    />
                </p>
                <p className='p-1'>
                    <Input
                        name="owner"
                        type="hidden"
                        onChange={this.onChange}
                        value={this.state.owner}
                    />
                </p>
                <p className='p-1'>
                    <Input
                        name="status"
                        type="hidden"
                        onChange={this.onChange}
                        value={this.state.status}
                    />
                </p>
                <Input
                        name="rootid"
                        type="hidden"
                        onChange={this.onChange}
                        value={this.state.rootid}
                        placeholder='rootid'
                    />
                <br />
                <Button  className='btn-warning'>Send enqury</Button>
            </Form>
        </div>
    )
}
}
