
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
function SideNavContent({title, one, two, three}) {
  return (
    <div className='py-3 border-b-2 border-b-gray-300'>
        <h3 className=" text-xl font-extrabold font-titleFont mt-2 mb-1 px-6">
            {title}
        </h3>
        <ul className='text-sm'>
            <li className="flex items-center justify-between hover:bg-gray-100 px-6 py-2 cursor-pointer">{one} <span><KeyboardArrowRightIcon /></span></li>
            <li className="flex items-center justify-between hover:bg-gray-100 px-6 py-2 cursor-pointer">{two} <span><KeyboardArrowRightIcon /></span></li>
            <li className="flex items-center justify-between hover:bg-gray-100 px-6 py-2 cursor-pointer">{three} <span><KeyboardArrowRightIcon /></span></li>
        </ul>
    </div>
  )
}

export default SideNavContent