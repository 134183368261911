import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    MenuIcon
} from "@heroicons/react/outline";

export default function Categories2({categories}) {
    const [isOpened, setIsOpened] = useState(false);
    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
      }
    return (
    <div className='bg-white p-1 border m-2 mb-4 border-gray-200 hover:bg-gray-50'>
        <div className='hidden sm:flex  m-auto sm:p-3'>
            <div className='w-96 p-0'>
                <div className='font-bold text-xl p-2 flex'>
                    <div className='p-1'>
                        <MenuIcon  className="h-6 mr-1" />
                    </div>
                    <div className='pb-2'>
                        Browse By Category
                    </div>
                    <hr />
                </div>
                {categories.slice(0,10).sort((a, b) => a.id > b.id ? 1:-1).map((category, key) => (  
                    <div className='flex'>
                        <div key={key} className='p-2'>
                            <img src={`${category.icon}`} width={20} height={20} rounded alt="" />
                        </div>
                        <div className='p-2'>
                            <Link to={`/${category.name}`} className="text-base">{category.name}</Link>
                        </div>
                    </div>
                ))}
            </div>
            <div className='p-4'>
                <img src='/img/banner/3.jpeg' alt=''/>
            </div>
        </div>
        <div className='w-full sm:hidden p-2'>
            <div className='p-2  bg-white border border-gray-200 hover:bg-gray-50 min-h-full hover:shadow-xl'>
                <div className='p-1 flex font-bold cursor-pointer'  onClick={toggle}>
                    <div>
                     <MenuIcon  className="h-6 mr-1" />
                    </div> 
                    <div>
                        Browse By Category
                    </div>
                </div>
                {isOpened && (
                <div className='p-2'>
                    <div className='pb-2 pt-2'>
                        <hr />
                    </div>
                    {categories.map((category, key) => (
                        <div key={key} className='p-1'>
                            <Link to={`/${category.name}`} className="text-base">{category.name}</Link>
                        </div>
                    ))}
                </div>
                )}
            </div>
        </div>
    </div>
    )
}
