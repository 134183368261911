import { Card } from "react-bootstrap";
import { Link } from "react-router-dom"

const Success = ({...props}) => {
  return (
    <div className="p-6">
      <Card className="p-4 m-auto text-center lg:w-3/5">
        <div className="text-2xl">SUCCESS!!</div>
        <p>Your transaction was processed. Thank you.</p>
        <Link to="/">Back to the Home...</Link>
      </Card>
    </div>
  );
}
 
export default Success;