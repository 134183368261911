import { Card } from "react-bootstrap";

const UploadSuccess = ({...props}) => {
  return (
    <div className="p-6">
      <Card className="p-4 m-auto text-center lg:w-2/5">
        <div className="text-2xl">UPLOAD SUCCESS!!</div>
        <div className="flex pt-5 m-auto">
            <div>
                <a href="/add-product" className="bg-yellow-300 rounded p-2 m-2">Add more products</a>
            </div>
            <div>
                <a href="/" className="bg-gray-300 rounded  p-2 m-2">Back to the Home...</a>
            </div>
        </div>
      </Card>
    </div>
  );
}
 
export default UploadSuccess;