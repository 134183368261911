
import { Image } from "react-bootstrap";
// import YellowAddToCartButton from "../feeds/shopping/YellowAddToCartButton";

function Product({id, title, category, image, price, product, moq, measurementType, ...props}) {
    return (
        <div className="p-2">
            <p>
                <a href={ `/product-detail/${product.id}/`}>
                    <center>
                        <Image className="max-h-40" 
                        src={product.image} alt={title} />
                    </center>
                </a>
            </p>
            <p className="pt-2 line-clamp-1 font-bold">{title}</p>
            <p className="pt-2">{price} </p>
            <p className="pt-2" style={{fontSize: "12px"}}><strong>{moq} {measurementType}</strong> (MOQ) </p>
            {/* <p className="pt-2"> 
                <YellowAddToCartButton addToCart={props.addToCart} product={product} />
            </p> */}
        </div>
    )
}
export default Product
