import { Image } from "react-bootstrap";
// import { useDispatch } from "react-redux";
// import { addToCart } from "../../redux/slice";

function SimilarProduct({id, title, description, category, image, price, product, ...props}) {
    // const dispatch = useDispatch()
 
    return (
        <div>
            <p className="" style={{height: "150px", padding:"10px"}}>
                <a href={ `/product-detail/${product.id}/`}>
                    <center>
                    <Image
                        className="object-cover max-h-40"
                    src={product.image} alt={title} rounded />
                    </center>
                </a>
            </p>
            <p className="pt-2 text-xs line-clamp-2 h-10">
                <a href={ `/product-detail/${product.id}/`}>{product.description}</a>
            </p>
            {/* <p>
                <button onClick={()=>dispatch(addToCart({
                    id:id,
                    title:title,
                    description:description,
                    image:image,
                    price:price,
                    category:category,
                    quantity:1
                }))} className='bg-yellow-400 text-white p-2 text-sm rounded-sm'>Add To Cart</button>
            </p> */}
            <p className="font-semibold pt-2 text-xl">
                ${price}
            </p>
            
        </div>
    )
}
export default SimilarProduct;
