import React from 'react';
import AddProduct from '../feeds/store/AddProduct';
import SmallLayout from './SmallLayout';

export default function UploadProducts({account, categories, dict}) {
  return (
    <SmallLayout account={account}>
        <AddProduct account={account} categories={categories} dict={dict} />
    </SmallLayout>
  )
}
